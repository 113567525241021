import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const inquiryApi = createApi({
  reducerPath: 'inquiryApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_API_CONNECT}/`,
  }),

  tagTypes: ['Inquiry'],
  refetchOnMountOrArgChange: 1,
  endpoints: (builder) => ({

    createInquiries: builder.mutation({
      query: (data) => {
        return {
          url: `inquiries`,
          method: 'POST',
          body: data,
          headers: {
            'X-Country-Code': 'AE',
            'Accept': 'application/json',
            'Content-Type': 'application/json',
          }
        }
      },
      // keepUnusedDataFor: 1,
      invalidatesTags: ['Inquiry'],
    }),




  }),

})

export const { useCreateInquiriesMutation } = inquiryApi