import React, { useState, useEffect, Fragment, useRef } from 'react';
import { BrowserRouter as Router, Switch, Route, Link, useHistory, Redirect, useLocation, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Button, Table, Spinner, Accordion, Card, Dropdown, Badge, Modal, DropdownButton } from 'react-bootstrap'
import "antd/dist/antd.min.css";
import './index.scss';
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { useTranslation } from 'react-i18next'
import { useSelector, useDispatch } from "react-redux";
import ReactPlayer from 'react-player'
import CarouselProducts from '../../Components/CarouselProducts';
import CarouselEvents from '../../Components/CarouselEvents';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import { useMediaQuery } from 'react-responsive'
import { useCreateInquiriesMutation } from '../../services/inquiries';
import ReCAPTCHA from "react-google-recaptcha";
import { addToInquiry } from "../../Reducers/authReducer";
import PhoneInput, { formatPhoneNumber, formatPhoneNumberIntl, isValidPhoneNumber, isPossiblePhoneNumber } from 'react-phone-number-input'
import Input from 'react-phone-number-input/input'
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { useParallax } from "react-scroll-parallax";

function Video() {
  const history = useHistory();
  const location = useLocation();
  const { t } = useTranslation()
  const { register, handleSubmit, reset, formState: { errors } } = useForm();
  const { userId, userToken, userEmail, languageCode, isInquiry } = useSelector((state) => state.auth);
  const isMobcreen = useMediaQuery({ query: '(min-width: 0px) and (max-width: 992px)' })
  const dispatch = useDispatch();
  const [createinquiry, createresult] = useCreateInquiriesMutation()

  const [isVideoLoaded, setIsVideoLoaded] = useState(false);
  const onLoadedData = () => {
    setIsVideoLoaded(true);
  };



  const [getPhone, setPhone] = useState('')
  const [getPhoneError, setPhoneError] = useState(false)
  const [isAlready, setAlready] = useState(false)
  const recaptchaRef = React.useRef();

  async function createInquiryFrom(data) {
    const token = await recaptchaRef.current.executeAsync();
    recaptchaRef.current.reset();
    if (isValidPhoneNumber(getPhone)) {
      if (isInquiry) {
        setAlready(true)
      } else {
        createinquiry({
          name: data.name,
          email: data.email,
          message: data.message,
          phone: getPhone,
          language: 'en',
          country: 'AE',
          captcha_token: token,
          domain: 'abfitness.ebtida.com',
          to_email: 'abfittnessgym.uae@gmail.com',
          abfitness: true,
          domainEmail: 'help@ebtida.com'
        })
      }
    } else {
      setPhoneError(true)
    }
  }

  useEffect(() => {
    if (createresult?.isSuccess) {
      reset()
      dispatch(addToInquiry({
        isInquiry: true,
      }));
    }
  }, [createresult]);


  const stockTimerId = useRef();
  React.useEffect(() => {
    stockTimerId.current = setInterval(() => {
      setAlready(false)
    }, 60000);
    return () => {
      clearInterval(stockTimerId.current);
    };
  }, []);






  const parallax = useParallax({
    // rotate: [0, 0],
    // translateY: ['-500px', '500px'],
    translateY: [0, 50],
    opacity: [1, 0],
    scale: [1.0, 1, 'easeOutCubic'],
    shouldAlwaysCompleteAnimation: true,
  });



  return (

    <div style={{ position: 'relative' }}>
      <div ref={parallax.ref} style={{ background: '#000', position: 'relative' }}>
        <ReactPlayer
          url={'https://cdn.ebtida.com/1711625226440-MMA-Series-WP.mp4'}
          playing={true}
          controls={false}
          loop={true}
          muted={true}
          playsinline={true}
          onReady={onLoadedData}
          width={'100%'}
          height={'100%'}
        />
        {!isMobcreen &&
          <div className="container event-banner-Text">
            <div className="HomeBanner-card" style={{ display: isMobcreen ? 'block' : 'flex', alignItems: 'center', width: '100%' }}>
              <div style={{ color: '#fff', fontWeight: 700, lineHeight: '58px', width: isMobcreen ? '100%' : '60%' }}>
                <span className='em-title1'> MMA </span>
                <br />
                <span className='em-subtitle1'>GLOBAL SERIES</span>
                <div className='em-subtitle2'>SUPPORTED BY</div>
              </div>
              <div className="HomeBanner-Text-Card2" style={{ width: '40%' }}>
                <div style={{ padding: '20px', borderRadius: '10px', textAlign: 'start' }}>
                  <div style={{ color: '#fff', fontSize: '3rem', fontWeight: 600 }}>UPCOMING</div>
                  <img
                    className='event-uc-image'
                    alt={'abfitness'}
                    src={`/svgs/event.svg`}
                    style={{ transition: "all 0.5s", width: '100%', marginTop: '-50px' }}
                  />
                </div>
              </div>
            </div>

          </div>
        }
      </div>
    </div>

  );
}

export default Video;
