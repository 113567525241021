import React, { useState, useEffect, Fragment, useRef } from 'react';
import { BrowserRouter as Router, Switch, Route, useHistory, Redirect, useLocation, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Button, Table, Spinner, Accordion, Card, Dropdown, Badge, Modal, DropdownButton } from 'react-bootstrap'
import "antd/dist/antd.min.css";
import './index.scss';
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from "react-redux";
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { useCreateInquiriesMutation } from '../../services/inquiries';
import Benefits from '../../Components/Benefits';
import { useParallax } from "react-scroll-parallax";


import ReCAPTCHA from "react-google-recaptcha";
import { addToInquiry } from "../../Reducers/authReducer";
import PhoneInput, { formatPhoneNumber, formatPhoneNumberIntl, isValidPhoneNumber, isPossiblePhoneNumber } from 'react-phone-number-input'
import Input from 'react-phone-number-input/input'
import SmartphoneIcon from '@mui/icons-material/Smartphone';

function Pricing() {
  const { userId, userToken, userEmail, userName, isMember, isPaymentMethod, isCustomer, languageCode, subscription } = useSelector((state) => state.auth);
  const history = useHistory();
  const location = useLocation();
  const { t } = useTranslation()
  const dispatch = useDispatch();
  const { register, handleSubmit, reset, formState: { errors } } = useForm();

  const [createinquiry, createresult] = useCreateInquiriesMutation()


  const benefitsList = languageCode === 'ar' ? Benefits?.ar : Benefits?.en


  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      // behavior: "smooth"
    });
  }, [])




  const [getPhone, setPhone] = useState('')
  const [getPhoneError, setPhoneError] = useState(false)
  const [isAlready, setAlready] = useState(false)
  const recaptchaRef = React.useRef();

  async function createInquiryFrom(data) {
    const token = await recaptchaRef.current.executeAsync();
    recaptchaRef.current.reset();
    if (isValidPhoneNumber(getPhone)) {
      // if (isInquiry) {
      //   setAlready(true)
      // } else {
      createinquiry({
        name: data.name,
        email: data.email,
        message: data.message,
        phone: getPhone,
        language: 'en',
        country: 'AE',
        captcha_token: token,
        state: 'open',
        domain: 'abfitness.ebtida.com',
        to_email: 'abfittnessgym.uae@gmail.com',
        abfitness: true,
        domainEmail: 'help@ebtida.com'
      })
      // }
    } else {
      setPhoneError(true)
    }
  }

  useEffect(() => {
    if (createresult?.isSuccess) {
      reset()
      dispatch(addToInquiry({
        isInquiry: true,
      }));
    }
  }, [createresult]);


  const stockTimerId = useRef();
  React.useEffect(() => {
    stockTimerId.current = setInterval(() => {
      setAlready(false)
    }, 60000);
    return () => {
      clearInterval(stockTimerId.current);
    };
  }, []);







  function displayHeading() {
    if (window.location.pathname === `/${languageCode}/7-days-pass`) {
      return (
        <div style={{ padding: '20px', background: '#fcfcfc' }}>
          <h2>{t('seven_day_titlea')}</h2>
          <h4>{t('seven_day_titleb')}</h4>
          <div><ArrowDownwardIcon /></div>
        </div>
      )
    } else if (window.location.pathname === `/7-days-pass`) {
      return (
        <div style={{ padding: '20px', background: '#fcfcfc' }}>
          <h2>{t('seven_day_titlea')}</h2>
          <h4>{t('seven_day_titleb')}</h4>
          <div><ArrowDownwardIcon /></div>
        </div>
      )
    } else {
      return (
        <h3>
          {t('membership_options')}
        </h3>
      )
    }
  }






  const parallax = useParallax({
    // rotate: [0, 0],
    // translateY: ['-500px', '500px'],
    translateY: [0, 50],
    opacity: [1, 0],
    scale: [1.0, 1, 'easeOutCubic'],
    shouldAlwaysCompleteAnimation: true,
  });


  return (
    <div className="Plans-page">
      <div ref={parallax.ref} className="section" style={{ background: '#040404', position: 'relative' }}>
        <div
          style={{
            backgroundImage: 'url(https://cdn.ebtida.com/1710876190277-GYM.webp)',
            backgroundPosition: 'center',
            backgroundSize: 'cover',
            height: '300px'
          }}>
          <div style={{
            position: 'absolute',
            height: '100%',
            top: 0,
            right: 0,
            left: 0,
            background: '#000000ad'
          }}>
          </div>

{/* 
          <div className='banner-title'>
            <div className="container">
              <div className="col-md-12">
                Memberships {displayHeading()}
              </div>
            </div>
          </div> */}

        </div>
      </div>

      <br />
      <div className="Lit-Banner" style={{ marginTop: '-125px' }}>
        <div className="container price-container">
          <div className="Dashboard-information">

            <div className="Dashboard-plans">
              <div className="row subscription-options-list-row" style={{justifyContent: 'center'}}>



              <div className="col-md-12">
                  <div className="HomeBanner-card" style={{ display: 'flex', alignItems: 'center', width: '100%', position:'relative', justifyContent: 'center', marginTop: '-40px' }}>
                
                    <div className="HomeBanner-Text-Card2" style={{ width: '50%' }}>
                      <div
                        style={{
                          padding: '20px',
                          borderRadius: '10px',
                          textAlign: 'start',
                          background: '#00000075'
                        }}>
                        <img
                          className='free-day-img'
                          alt={'abfitness'}
                          src={`https://cdn.ebtida.com/1712770492998-Free-Pass-1.png`}
                          style={{ transition: "all 0.5s" }}
                        />

                        {createresult?.error?.data?.success &&
                          <div style={{ textAlign: 'center', color: 'tomato', fontSize: '12px', webkitTextTtroke: '1px green', marginBottom: '10px' }} >
                            {createresult?.error?.data?.success}
                          </div>
                        }

                        {createresult?.error?.data?.error &&
                          <div style={{ textAlign: 'center', color: 'tomato', fontSize: '12px', webkitTextTtroke: '1px green', marginBottom: '10px' }} >
                            {createresult?.error?.data?.error}
                          </div>
                        }

                        {createresult?.data?.success &&
                          <div style={{ textAlign: 'center', color: 'green', fontSize: '12px', webkitTextTtroke: '1px green', marginBottom: '10px' }} >
                            {createresult?.data?.success}
                          </div>
                        }

                        <form onSubmit={handleSubmit(createInquiryFrom)}>
                          <ReCAPTCHA
                            ref={recaptchaRef}
                            size="invisible"
                            sitekey="6LfD62EnAAAAAJ83k0BznR8m_cXInxBFjtTokspL"
                          />
                          <input
                            placeholder='Full Name'
                            className='form-control hp-input'
                            id="name"
                            aria-invalid={errors.name ? "true" : "false"}
                            {...register('name', { required: true })}
                          />
                          <input
                            placeholder='Email'
                            className='form-control hp-input'
                            id="email"
                            aria-invalid={errors.email ? "true" : "false"}
                            {...register('email', { required: true })}
                          />

                          {getPhoneError &&
                            <>
                              {getPhone && isValidPhoneNumber(getPhone) ? '' :
                                <small
                                  style={{ color: 'tomato', textTransform: 'capitalize', fontSize: '12px', webkitTextTtroke: '1px tomato', marginBottom: '10px' }}>{'Invalid phone number'}
                                </small>
                              }
                            </>
                          }
                          <Input
                            className="hp-input"
                            country="AE"
                            international
                            withCountryCallingCode
                            value={getPhone}
                            onChange={setPhone} />
                          <input
                            placeholder='Message'
                            className='form-control hp-input'
                            id="message"
                            aria-invalid={errors.message ? "true" : "false"}
                            {...register('message', { required: true })}
                          />
                          <Button
                            style={{ height: '50px' }}
                            disabled={createresult.isLoading}
                            className="inquiry-form-button"
                            type="submit">
                            {createresult.isLoading ? t('loading') : t('submit')}
                          </Button>
                        </form>
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', padding: '20px 0px 10px 0px' }}>
                          <SmartphoneIcon style={{ background: '#fcfcfc4a', borderRadius: '100px', padding: '4px', color: '#f7ee23', fontSize: '32px' }} />
                          <div style={{ margin: '0 10px', color: '#fff' }}>{t('phone_numbersb')}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>






              </div>
              <br /><br />
              <div className="row plans-row">
                <div className="col-md-6" id='benefits'>
                  <div className="Page-card" style={{ background: '#000' }}>
                    <div className="Page-card-header">
                      <h4><strong>{t('gym_membership_benefits')}</strong></h4>
                    </div>
                    <ul className="Page-card-list" style={{ padding: languageCode === 'en' ? '0px 0px 0px 30px' : '0px 30px 0px 0px' }}>
                      {benefitsList?.map((item, i) =>
                        <li className="Page-card-list-item" key={i}>
                          <p>{item}</p>
                        </li>
                      )}
                    </ul>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
      <br /><br />
    </div >
  );
}

export default Pricing;
