import { configureStore } from '@reduxjs/toolkit'
import { setupListeners } from '@reduxjs/toolkit/query';
import { inquiryApi } from '../services/inquiries';
import authReducer from "../Reducers/authReducer";

export const store = configureStore({
  reducer: {
    auth: authReducer,
    [inquiryApi.reducerPath]: inquiryApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(inquiryApi.middleware)
})

setupListeners(store.dispatch)
