import React, { useState, useEffect, Fragment } from 'react';
import { BrowserRouter as Router, Switch, Route, Link, useHistory, Redirect, useLocation, useParams } from "react-router-dom";
import "antd/dist/antd.min.css";
import './index.scss';
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Navigation } from "swiper";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import 'react-lazy-load-image-component/src/effects/blur.css';
import { useMediaQuery } from 'react-responsive'
function Trainers({ data, loading, length, title, languageCode, background, backgrounds, padding, getLanguage }) {
  const isMobcreen = useMediaQuery({ query: '(min-width: 0px) and (max-width: 992px)' })
  return (
    <div className="recentlySection" style={{ background: backgrounds }}>

      <div className="row">
        <div className="col-md-12" dir={`${languageCode === 'ar' || languageCode === 'urdu' ? 'rtl' : 'ltr'}`}>
          <Swiper
            slidesPerView={isMobcreen ? 1 : 2}
            spaceBetween={15}
            slidesPerGroup={1}
            loop={true}
            loopFillGroupWithBlank={false}
            pagination={{
              clickable: true,
            }}
            navigation={15 > 2 ? true : false}
            modules={[Pagination, Navigation]}
            className="mySwiper recentlySectionSwiper"
            style={{ background: background, padding: padding }}
          >
            <SwiperSlide className="Dashboard-books-card" key={1}>
              <img
                alt={'abfitness'}
                title={'abfitness'}
                effect="blur"
                width={'100%'}
                className="img-list-img"
                style={{ transition: "all 0.5s", border: '1px solid #686758', borderRadius: '10px' }}
                src={`/trainers/WhatsApp-Image1.jpeg`}
              />
            </SwiperSlide>

            <SwiperSlide className="Dashboard-books-card" key={3}>
              <img
                alt={'abfitness'}
                title={'abfitness'}
                effect="blur"
                width={'100%'}
                className="img-list-img"
                style={{ transition: "all 0.5s", border: '1px solid #686758', borderRadius: '10px' }}
                src={`/trainers/WhatsAppImage6.jpeg`}
              />
            </SwiperSlide>
            <SwiperSlide className="Dashboard-books-card" key={4}>
              <img
                alt={'abfitness'}
                title={'abfitness'}
                effect="blur"
                width={'100%'}
                className="img-list-img"
                style={{ transition: "all 0.5s", border: '1px solid #686758', borderRadius: '10px' }}
                src={`/trainers/WhatsAppImage7.jpeg`}
              />
            </SwiperSlide>
            <SwiperSlide className="Dashboard-books-card" key={5}>
              <img
                alt={'abfitness'}
                title={'abfitness'}
                effect="blur"
                width={'100%'}
                className="img-list-img"
                style={{ transition: "all 0.5s", border: '1px solid #686758', borderRadius: '10px' }}
                src={`/trainers/WhatsAppImage8.jpeg`}
              />
            </SwiperSlide>
            <SwiperSlide className="Dashboard-books-card" key={8}>
              <img
                alt={'abfitness'}
                title={'abfitness'}
                effect="blur"
                width={'100%'}
                className="img-list-img"
                style={{ transition: "all 0.5s", border: '1px solid #686758', borderRadius: '10px' }}
                src={`/trainers/WhatsAppImage9.jpeg`}
              />
            </SwiperSlide>
            <SwiperSlide className="Dashboard-books-card" key={6}>
              <img
                alt={'abfitness'}
                title={'abfitness'}
                effect="blur"
                width={'100%'}
                className="img-list-img"
                style={{ transition: "all 0.5s", border: '1px solid #686758', borderRadius: '10px' }}
                src={`/trainers/WhatsAppImage10.jpeg`}
              />
            </SwiperSlide>
            <SwiperSlide className="Dashboard-books-card" key={7}>
              <img
                alt={'abfitness'}
                title={'abfitness'}
                effect="blur"
                width={'100%'}
                className="img-list-img"
                style={{ transition: "all 0.5s", border: '1px solid #686758', borderRadius: '10px' }}
                src={`/trainers/WhatsAppImage11.jpeg`}
              />
            </SwiperSlide>
            <SwiperSlide className="Dashboard-books-card" key={7}>
              <img
                alt={'abfitness'}
                title={'abfitness'}
                effect="blur"
                width={'100%'}
                className="img-list-img"
                style={{ transition: "all 0.5s", border: '1px solid #686758', borderRadius: '10px' }}
                src={`/trainers/WhatsAppImage12.jpeg`}
              />
            </SwiperSlide>

          </Swiper>
        </div>
      </div>
    </div>

  );
}

export default Trainers;
