import React, { useState, useEffect, Fragment, useRef } from 'react';
import { BrowserRouter as Router, Switch, Route, Link, useHistory, Redirect, useLocation, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Button, Table, Spinner, Accordion, Card, Dropdown, Badge, Modal, DropdownButton } from 'react-bootstrap'
import "antd/dist/antd.min.css";
import './index.scss';
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { useTranslation } from 'react-i18next'
import { useSelector, useDispatch } from "react-redux";
import ReactPlayer from 'react-player'
import CarouselEvents from '../../Components/CarouselEvents';
import 'react-lazy-load-image-component/src/effects/blur.css';
import { useMediaQuery } from 'react-responsive'
import { useCreateInquiriesMutation } from '../../services/inquiries';
import ReCAPTCHA from "react-google-recaptcha";
import { addToInquiry } from "../../Reducers/authReducer";
import PhoneInput, { formatPhoneNumber, formatPhoneNumberIntl, isValidPhoneNumber, isPossiblePhoneNumber } from 'react-phone-number-input'
import Input from 'react-phone-number-input/input'
import SmartphoneIcon from '@mui/icons-material/Smartphone';
import { ParallaxProvider } from 'react-scroll-parallax';
import Banner from './Banner';


function Home() {
  const history = useHistory();
  const location = useLocation();
  const { t } = useTranslation()
  const { register, handleSubmit, reset, formState: { errors } } = useForm();
  const { userId, userToken, userEmail, languageCode, isInquiry } = useSelector((state) => state.auth);
  const isMobcreen = useMediaQuery({ query: '(min-width: 0px) and (max-width: 992px)' })
  const dispatch = useDispatch();
  const [createinquiry, createresult] = useCreateInquiriesMutation()

  const [isVideoLoaded, setIsVideoLoaded] = useState(false);
  const onLoadedData = () => {
    setIsVideoLoaded(true);
  };



  const [getPhone, setPhone] = useState('')
  const [getPhoneError, setPhoneError] = useState(false)
  const [isAlready, setAlready] = useState(false)
  const recaptchaRef = React.useRef();

  async function createInquiryFrom(data) {
    const token = await recaptchaRef.current.executeAsync();
    recaptchaRef.current.reset();
    if (isValidPhoneNumber(getPhone)) {
      // if (isInquiry) {
      //   setAlready(true)
      // } else {
      createinquiry({
        name: data.name,
        email: data.email,
        message: data.message,
        phone: getPhone,
        language: 'en',
        country: 'AE',
        captcha_token: token,
        state: 'open',
        domain: 'abfitness.ebtida.com',
        to_email: 'abfittnessgym.uae@gmail.com',
        abfitness: true,
        domainEmail: 'help@ebtida.com'
      })
      // }
    } else {
      setPhoneError(true)
    }
  }

  useEffect(() => {
    if (createresult?.isSuccess) {
      reset()
      dispatch(addToInquiry({
        isInquiry: true,
      }));
    }
  }, [createresult]);


  const stockTimerId = useRef();
  React.useEffect(() => {
    stockTimerId.current = setInterval(() => {
      setAlready(false)
    }, 60000);
    return () => {
      clearInterval(stockTimerId.current);
    };
  }, []);



  function checkExist() {
    if (createresult?.isSuccess) {
      return true
    } else if (isAlready) {
      return true
    } else {
      return false
    }
  }


  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      // behavior: "smooth"
    });
  }, [])



  useEffect(() => {
    if (createresult?.isSuccess) {
      history.push(`${window.location.pathname}?freepass-thank-you`)
    }
  }, [createresult?.isSuccess])


  return (

    <div className="Home-page">
      <Banner />
      {isMobcreen &&

        <div className="container HomeBanner-Text" style={{ position: 'relative', marginTop: '-280px' }}>
          <div className="HomeBanner-card" style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
            <div className="HomeBanner-Text-Card1" style={{
              color: '#fff',
              fontSize: '65px',
              fontWeight: 700,
              lineHeight: '58px',
              width: '60%'
            }}>
            </div>
            <div className="HomeBanner-Text-Card2" style={{ width: '40%' }}>
              <div
                style={{
                  padding: '20px',
                  borderRadius: '10px',
                  textAlign: 'start',
                  background: '#00000075'
                }}>
                <img
                  className='free-day-img'
                  alt={'abfitness'}
                  src={`https://cdn.ebtida.com/1712770492998-Free-Pass-1.png`}
                  style={{ transition: "all 0.5s" }}
                />

                {createresult?.error?.data?.success &&
                  <div style={{ textAlign: 'center', color: 'tomato', fontSize: '12px', webkitTextTtroke: '1px green', marginBottom: '10px' }} >
                    {createresult?.error?.data?.success}
                  </div>
                }

                {createresult?.error?.data?.error &&
                  <div style={{ textAlign: 'center', color: 'tomato', fontSize: '12px', webkitTextTtroke: '1px green', marginBottom: '10px' }} >
                    {createresult?.error?.data?.error}
                  </div>
                }

                {createresult?.data?.success &&
                  <div style={{ textAlign: 'center', color: 'green', fontSize: '12px', webkitTextTtroke: '1px green', marginBottom: '10px' }} >
                    {createresult?.data?.success}
                  </div>
                }

                <form onSubmit={handleSubmit(createInquiryFrom)}>
                  <ReCAPTCHA
                    ref={recaptchaRef}
                    size="invisible"
                    sitekey="6LfD62EnAAAAAJ83k0BznR8m_cXInxBFjtTokspL"
                  />
                  <input
                    placeholder='Full Name'
                    className='form-control hp-input'
                    id="name"
                    aria-invalid={errors.name ? "true" : "false"}
                    {...register('name', { required: true })}
                  />
                  <input
                    placeholder='Email'
                    className='form-control hp-input'
                    id="email"
                    aria-invalid={errors.email ? "true" : "false"}
                    {...register('email', { required: true })}
                  />

                  {getPhoneError &&
                    <>
                      {getPhone && isValidPhoneNumber(getPhone) ? '' :
                        <small
                          style={{ color: 'tomato', textTransform: 'capitalize', fontSize: '12px', webkitTextTtroke: '1px tomato', marginBottom: '10px' }}>{'Invalid phone number'}
                        </small>
                      }
                    </>
                  }
                  <Input
                    className="hp-input"
                    country="AE"
                    international
                    withCountryCallingCode
                    value={getPhone}
                    onChange={setPhone} />
                  <input
                    placeholder='Message'
                    className='form-control hp-input'
                    id="message"
                    aria-invalid={errors.message ? "true" : "false"}
                    {...register('message', { required: true })}
                  />
                  <Button
                    style={{ height: '50px' }}
                    disabled={createresult.isLoading}
                    className="inquiry-form-button"
                    type="submit">
                    {createresult.isLoading ? t('loading') : t('submit')}
                  </Button>
                </form>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', padding: '20px 0px 10px 0px' }}>
                  <SmartphoneIcon style={{ background: '#fcfcfc4a', borderRadius: '100px', padding: '4px', color: '#f7ee23', fontSize: '32px' }} />
                  <div style={{ margin: '0 10px', color: '#fff' }}>{t('phone_numbersb')}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      }





      <div className="Lit-Banner"
        id="what-we-offer"
        style={{
          background: '#040404',
          position: 'relative',
          backgroundImage: 'url(https://cdn.ebtida.com/1711625522625-Group-whatwe.jpeg)',
          backgroundPosition: 'top center',
          backgroundSize: 'cover',
        }}>
        <div style={{
          position: 'absolute',
          height: '100%',
          top: 0,
          right: 0,
          left: 0,
          background: '#000000ad'
        }}>

        </div>
        <div className="container">

          <div className="Lit-banner-card">

            <div className="row hp-row-direction">
              <div className={`col-md-7`}>
                <div className="row Banner-row">
                  <div className={`col-md-6 Banner-image`} style={{ padding: '5px' }}>
                    <Link to="/personal-trainers">
                      <img
                        alt={'abfitness'}
                        src={`https://cdn.ebtida.com/1710876207211-1.webp`}
                        style={{ transition: "all 0.5s", width: '100%' }}
                      />
                    </Link>
                    <h1 className="Home-image-title">
                      {t('personal_training')}
                    </h1>
                  </div>
                  <div className={`col-md-6 Banner-image`} style={{ padding: '5px' }}>
                    <Link to="/gym">
                      <img
                        alt={'abfitness'}
                        src={`https://cdn.ebtida.com/1710876190277-GYM.webp`}
                        style={{ transition: "all 0.5s", width: '100%' }}
                      />
                    </Link>
                    <h1 className="Home-image-title">
                      {t('gym')}
                    </h1>
                  </div>
                </div>
                <div className="row Banner-row">
                  <div className={`col-md-6 Banner-image`} style={{ padding: '5px' }}>
                    <Link to="/classes">
                      <img
                        alt={'abfitness'}
                        src={`https://cdn.ebtida.com/1710876199675-GroupClasses.webp`}
                        style={{ transition: "all 0.5s", width: '100%' }}
                      />
                    </Link>
                    <h1 className="Home-image-title">
                      {t('group_classes')}
                    </h1>
                  </div>
                  <div className={`col-md-6 Banner-image`} style={{ padding: '5px' }}>
                    <Link to="/events">
                      <img
                        alt={'abfitness'}
                        src={`https://cdn.ebtida.com/1710876179367-MMA-1.webp`}
                        style={{ transition: "all 0.5s", width: '100%' }}
                      />
                    </Link>
                    <h1 className="Home-image-title">
                      {t('mma')}
                    </h1>
                  </div>
                </div>
              </div>

              <div className={`col-md-5`}>
                <h1 className="home-section-title" style={{ textAlign: 'center' }}>{t('what_we_offer')}</h1>
                <div className='home-section-text' style={{ textAlign: 'center', color: '#fff', fontSize: '3.5rem', fontWeight: 600, textTransform: 'uppercase' }}>Train your <br />mind & body</div>
              </div>
            </div>

          </div>
        </div>


        <div className="container" style={{ position: 'relative' }}>
          <h1 className="home-facilities-title" style={{ textAlign: 'center' }}>{t('facilities')}</h1>
          <div className="Lit-banner-card">
            <div className="row Banner-row">
              <div className={`home-facilities`}>
                <img
                  alt={'abfitness'}
                  title={'abfitness'}
                  effect="blur"
                  style={{ transition: "all 0.5s" }} src={`https://dj70nht8amdum.cloudfront.net/abfitness/facilities/gym.png`}
                />
                <h1 className="Home-facilities-title"> {t('gym')}</h1>
              </div>
              <div className={`home-facilities`}>
                <img
                  alt={'abfitness'}
                  title={'abfitness'}
                  effect="blur"
                  src={`https://dj70nht8amdum.cloudfront.net/abfitness/facilities/cycle-studio.png`}
                />
                <h1 className="Home-facilities-title"> {t('cycle_studio')} </h1>
              </div>
              <div className={`home-facilities`}>
                <img
                  alt={'abfitness'}
                  title={'abfitness'}
                  effect="blur"
                  src={`https://dj70nht8amdum.cloudfront.net/abfitness/facilities/physiotherapy.png`}
                />
                <h1 className="Home-facilities-title"> {t('physiotherapy_studio')} </h1>
              </div>
              <div className={`home-facilities`}>
                <img
                  alt={'abfitness'}
                  title={'abfitness'}
                  effect="blur"
                  src={`https://dj70nht8amdum.cloudfront.net/abfitness/facilities/swimming-pool.png`}
                />
                <h1 className="Home-facilities-title"> {t('swimming_pool')} </h1>
              </div>
              <div className={`home-facilities`}>
                <img
                  alt={'abfitness'}
                  title={'abfitness'}
                  effect="blur"
                  src={`https://dj70nht8amdum.cloudfront.net/abfitness/facilities/mma-arena.png`}
                />
                <h1 className="Home-facilities-title"> {t('mma_arena')} </h1>
              </div>
            </div>
          </div>

          <h1 className="home-facilities-title" style={{ textAlign: 'center' }}>{t('amenities')}</h1>
          <div className="Lit-banner-card">
            <div className="row Banner-row">
              <div className={`home-facilities`}>
                <img
                  alt={'abfitness'}
                  title={'abfitness'}
                  effect="blur"
                  style={{ transition: "all 0.5s" }} src={`https://dj70nht8amdum.cloudfront.net/abfitness/amenities/yacht-views.png`}
                />
                <h1 className="Home-facilities-title"> {t('yacht_views')}</h1>
              </div>
              <div className={`home-facilities`}>
                <img
                  alt={'abfitness'}
                  title={'abfitness'}
                  effect="blur"
                  src={`https://dj70nht8amdum.cloudfront.net/abfitness/amenities/lockers.png`}
                />
                <h1 className="Home-facilities-title"> {t('lockers')} </h1>
              </div>
              <div className={`home-facilities`}>
                <img
                  alt={'abfitness'}
                  title={'abfitness'}
                  effect="blur"
                  src={`https://dj70nht8amdum.cloudfront.net/abfitness/amenities/sauna.png`}
                />
                <h1 className="Home-facilities-title"> {t('sauna')} </h1>
              </div>
              <div className={`home-facilities`}>
                <img
                  alt={'abfitness'}
                  title={'abfitness'}
                  effect="blur"
                  src={`https://dj70nht8amdum.cloudfront.net/abfitness/amenities/changing-room.png`}
                />
                <h1 className="Home-facilities-title"> {t('changing_room')} </h1>
              </div>
              <div className={`home-facilities`}>
                <img
                  alt={'abfitness'}
                  title={'abfitness'}
                  effect="blur"
                  src={`https://dj70nht8amdum.cloudfront.net/abfitness/amenities/shower-rooms.png`}
                />
                <h1 className="Home-facilities-title"> {t('shower_rooms')} </h1>
              </div>
              <div className={`home-facilities`}>
                <img
                  alt={'abfitness'}
                  title={'abfitness'}
                  effect="blur"
                  src={`https://dj70nht8amdum.cloudfront.net/abfitness/amenities/parking.png`}
                />
                <h1 className="Home-facilities-title"> {t('parking')} </h1>
              </div>
            </div>
          </div>

        </div>
      </div>





      <div className="Lit-Banner"
        id="what-we-offer"
        style={{
          background: '#040404',
          position: 'relative'
        }}>
        <div className="container">
          <div className="Lit-banner-card">

            <h1 className="home-facilities-title" style={{ textAlign: 'center' }}>{t('News & Events')}</h1>

            <div className="row Banner-row">
              <div className={`col-md-3`}>
                <img
                  alt={'abfitness'}
                  src={`https://cdn.ebtida.com/1711624988629-post1.png.webp`}
                  style={{
                    transition: "all 0.5s",
                    width: '100%',
                    height: '260px',
                    objectFit: 'cover',
                    border: '1px solid #eee'
                  }}
                />
                <h1 className="Home-image-title" style={{ whiteSpace: 'break-spaces' }}>
                  {t('Ringside Rampage – The Launch – 4th June 2022')}
                </h1>
                <div style={{ color: '#aaa', fontSize: '14px' }}>June 4, 2022</div>
                <p style={{ color: '#aaa', whiteSpace: 'break-spaces', fontSize: '14px' }}>DUBAI MARITIME CITY HOSTS WORLD BOXING COUNCIL MIDDLE EAST CHAMPIONSHIPS Ringside Rampage returns with part IV; The Launch, on the 4th of June 2022 with</p>
              </div>
              <div className={`col-md-3`}>
                <img
                  alt={'abfitness'}
                  src={`https://cdn.ebtida.com/1711624999279-post2.jpg.webp`}
                  style={{
                    transition: "all 0.5s",
                    width: '100%',
                    height: '260px',
                    objectFit: 'cover',
                    border: '1px solid #eee'
                  }}
                />
                <h1 className="Home-image-title" style={{ whiteSpace: 'break-spaces' }}>
                  {t('Hexagon MMA France – 28th August 2022')}
                </h1>
                <div style={{ color: '#aaa', fontSize: '14px' }}>August 28, 2022</div>
                <p style={{ color: '#aaa', whiteSpace: 'break-spaces', fontSize: '14px' }}>Witness history at the HEXAGONE MMA World Championship in Dubai on August 28, 2022! This groundbreaking event features French and international fighters in a VIP</p>
              </div>
              <div className={`col-md-3`}>
                <img
                  alt={'abfitness'}
                  src={`https://cdn.ebtida.com/1711625009515-post3.jpg.webp`}
                  style={{
                    transition: "all 0.5s",
                    width: '100%',
                    height: '260px',
                    objectFit: 'cover',
                    border: '1px solid #eee'
                  }}
                />
                <h1 className="Home-image-title" style={{ whiteSpace: 'break-spaces' }}>
                  {t('Skechers Performance Run – 28th May 2023')}
                </h1>
                <div style={{ color: '#aaa', fontSize: '14px' }}>May 28, 2023</div>
                <p style={{ color: '#aaa', whiteSpace: 'break-spaces', fontSize: '14px' }}>Enter the SKECHERS Performance Run at Mina Rashid Marina on Sunday, 28 May, 6am start. The course takes you through the beautiful P&O Marina near</p>
              </div>
              <div className={`col-md-3`}>
                <img
                  alt={'abfitness'}
                  src={`https://cdn.ebtida.com/1711625017953-abfitness-mma-23rd.png`}
                  style={{
                    transition: "all 0.5s",
                    width: '100%',
                    height: '260px',
                    objectFit: 'cover',
                    border: '1px solid #eee'
                  }}
                />
                <h1 className="Home-image-title" style={{ whiteSpace: 'break-spaces' }}>
                  {t('MMA Global Event at AB Fitness Marina')}
                </h1>
                <div style={{ color: '#aaa', fontSize: '14px' }}>March 23, 2024</div>
                <p style={{ color: '#aaa', whiteSpace: 'break-spaces', fontSize: '14px' }}>
                  WITNESS THE CLASH OF TITANS AT AB FITNESS! MMA Global Series made a TRIUMPHANT return to Dubai on the 23rd of March 2024.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className='section'
        style={{
          background: '#040404',
          position: 'relative',

        }}>
        <div className="container">
          <div className="row" style={{ justifyContent: 'center' }}>
            <div className={`col-md-4`}>
              <br /><br />
              <div style={{ border: '0.5px solid #fff' }}></div>
              <br /> <br />
            </div>
          </div>
        </div>
      </div>


      <div
        className='section'
        style={{
          background: '#040404',
          position: 'relative'
        }}>
        <div className="container">
          <div className="row">
            <div className={`col-md-4`}>
              <div style={{ textAlign: 'center' }}>
                <h1 className="Home-image-title" style={{ fontSize: '2.5rem' }}>
                  {t('24/7')}
                </h1>
                <div style={{ color: '#fff', fontSize: '1.5rem' }}>OPEN</div>
              </div>
            </div>
            <div className={`col-md-4`}>
              <div style={{ textAlign: 'center' }}>
                <h1 className="Home-image-title" style={{ fontSize: '2.5rem' }}>
                  {t('120,000')}
                </h1>
                <div style={{ color: '#fff', fontSize: '1.5rem' }}>SQUARE FEET</div>
              </div>
            </div>
            <div className={`col-md-4`}>
              <div style={{ textAlign: 'center' }}>
                <h1 className="Home-image-title" style={{ fontSize: '2.5rem' }}>
                  {t('500+')}
                </h1>
                <div style={{ color: '#fff', fontSize: '1.5rem' }}>EQUIPMENT</div>
              </div>
            </div>
          </div>
          <br /><br />
          <div className="row">
            <div className={`col-md-4`}>
              <div style={{ textAlign: 'center' }}>
                <h1 className="Home-image-title" style={{ fontSize: '2.5rem' }}>
                  {t('124M INDOOR')}
                </h1>
                <div style={{ color: '#fff', fontSize: '1.5rem' }}>TRACK LANE</div>
              </div>
            </div>
            <div className={`col-md-4`}>
              <div style={{ textAlign: 'center' }}>
                <h1 className="Home-image-title" style={{ fontSize: '2.5rem' }}>
                  {t('EPIC NUTRITION')}
                </h1>
                <div style={{ color: '#fff', fontSize: '1.5rem' }}>SECTION</div>
              </div>
            </div>
            <div className={`col-md-4`}>
              <div style={{ textAlign: 'center' }}>
                <h1 className="Home-image-title" style={{ fontSize: '2.5rem' }}>
                  {t('MARINA')}
                </h1>
                <div style={{ color: '#fff', fontSize: '1.5rem' }}>VIEW</div>
              </div>
            </div>
          </div>
        </div>
      </div>



      <div
        className='section'
        style={{
          background: '#040404',
          position: 'relative',
          padding: '20px 0px 20px 0px'
        }}>
        <div className="container">
          <div className="row" style={{ justifyContent: 'center' }}>
            <div className={`col-md-10`}>
              <br /> <br />
              <div style={{ color: '#fff', textAlign: 'center' }}>This is the time to change the image of a gym in your mind. Imagine an open space gym, 120 000 sqft with yatchet views that are not only a site to see but will keep you motivated for a future like no other. AB Fitness is a one of a kind of a gym, the one stop hub of fitness, catering for all kind of demographics, individuals, through our facility and it add on’s. Our gym is equipped with latest technology Technogym, as we understand that no compromise is worth it when it comes to our clients after all your health is not an expense but an investment and we are willing to invest in our client’s health.

              </div>
              <br /> <br />
            </div>
          </div>
        </div>
      </div>



      <div className="Lit-Banner membership-offers" id="membership-offers" style={{ backgroundColor: '#040404', position: 'relative' }}>
        <div className="container">
          <h1 className="home-section-title" style={{ textAlign: 'center' }}>{t('protein')} {t('shop')}</h1>
          {/* <Button className="join-buttom">{t('shop')} {t('now')}</Button> */}
        </div>
      </div>

      <div style={{ opacity: isVideoLoaded ? 1 : 1, position: 'relative' }}>
        <div className="home-membership-section" style={{ position: 'relative' }}>
          <img
            alt={'abfitness'}
            src={`https://dj70nht8amdum.cloudfront.net/abfitness/protein-shop-${languageCode === 'en' ? 'en.jpg' : 'ar.png'}`}
            style={{ transition: "all 0.5s", width: '100%' }}
            className="home-members-image"
          />
        </div>
      </div>

      <CarouselEvents
        title={t('events')}
        languageCode={languageCode}
        background={'#fff0'}
        backgrounds={'#000'}
        padding={'0px'}
        getLanguage={languageCode}
      />



    </div>

  );
}

export default Home;
