import React, { useState, useEffect, Fragment, useRef } from 'react';
import { BrowserRouter as Router, Switch, Route, Link, useHistory, Redirect, useLocation, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Button, Table, Spinner, Accordion, Card, Dropdown, Badge, Modal, DropdownButton } from 'react-bootstrap'
import "antd/dist/antd.min.css";
import './index.scss';
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { useTranslation } from 'react-i18next'
import { useSelector, useDispatch } from "react-redux";
import ReactPlayer from 'react-player'
import CarouselProducts from '../../Components/CarouselProducts';
import CarouselEvents from '../../Components/CarouselEvents';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import { useMediaQuery } from 'react-responsive'
import { useCreateInquiriesMutation } from '../../services/inquiries';
import ReCAPTCHA from "react-google-recaptcha";
import { addToInquiry } from "../../Reducers/authReducer";
import PhoneInput, { formatPhoneNumber, formatPhoneNumberIntl, isValidPhoneNumber, isPossiblePhoneNumber } from 'react-phone-number-input'
import Input from 'react-phone-number-input/input'
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { ParallaxProvider } from 'react-scroll-parallax';
import Video from './Video';

function Event() {
  const history = useHistory();
  const location = useLocation();
  const { t } = useTranslation()
  const { register, handleSubmit, reset, formState: { errors } } = useForm();
  const { userId, userToken, userEmail, languageCode, isInquiry } = useSelector((state) => state.auth);
  const isMobcreen = useMediaQuery({ query: '(min-width: 0px) and (max-width: 992px)' })
  const dispatch = useDispatch();
  const [createinquiry, createresult] = useCreateInquiriesMutation()

  const [isVideoLoaded, setIsVideoLoaded] = useState(false);
  const onLoadedData = () => {
    setIsVideoLoaded(true);
  };



  const [getPhone, setPhone] = useState('')
  const [getPhoneError, setPhoneError] = useState(false)
  const [isAlready, setAlready] = useState(false)
  const recaptchaRef = React.useRef();

  async function createInquiryFrom(data) {
    const token = await recaptchaRef.current.executeAsync();
    recaptchaRef.current.reset();
    if (isValidPhoneNumber(getPhone)) {
      if (isInquiry) {
        setAlready(true)
      } else {
        createinquiry({
          name: data.name,
          email: data.email,
          message: data.message,
          phone: getPhone,
          language: 'en',
          country: 'AE',
          captcha_token: token,
          domain: 'abfitness.ebtida.com',
          to_email: 'abfittnessgym.uae@gmail.com',
          abfitness: true,
          domainEmail: 'help@ebtida.com'
        })
      }
    } else {
      setPhoneError(true)
    }
  }

  useEffect(() => {
    if (createresult?.isSuccess) {
      reset()
      dispatch(addToInquiry({
        isInquiry: true,
      }));
    }
  }, [createresult]);


  const stockTimerId = useRef();
  React.useEffect(() => {
    stockTimerId.current = setInterval(() => {
      setAlready(false)
    }, 60000);
    return () => {
      clearInterval(stockTimerId.current);
    };
  }, []);



  function checkExist() {
    if (createresult?.isSuccess) {
      return true
    } else if (isAlready) {
      return true
    } else {
      return false
    }
  }


  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      // behavior: "smooth"
    });
  }, [])



  return (

    <div className="event-page">


      <Video />


      {isMobcreen &&
        <div className="container event-banner-Text">
          <div className="HomeBanner-card" style={{ display: isMobcreen ? 'block' : 'flex', alignItems: 'center', width: '100%' }}>
            <div style={{ color: '#fff', fontWeight: 700, lineHeight: '58px', width: isMobcreen ? '100%' : '60%' }}>
              <span className='em-title1'> MMA </span>
              <br />
              <span className='em-subtitle1'>GLOBAL SERIES</span>
              <div className='em-subtitle2'>SUPPORTED BY</div>
            </div>
            <div className="HomeBanner-Text-Card2" style={{ width: '40%' }}>
              <div style={{ padding: '20px', borderRadius: '10px', textAlign: 'start' }}>
                <div style={{ color: '#fff', fontSize: '3rem', fontWeight: 600 }}>UPCOMING</div>
                <img
                  className='event-uc-image'
                  alt={'abfitness'}
                  src={`/svgs/event.svg`}
                  style={{ transition: "all 0.5s", width: '100%', marginTop: '-50px' }}
                />
              </div>
            </div>
          </div>

        </div>
      }



      <div className="container">







        <div className="Lit-Banner"
          id="what-we-offer"
          style={{
            background: '#040404',
            position: 'relative'
          }}>





          <div className="HomeBanner-card" style={{ display: isMobcreen ? 'block' : 'flex', alignItems: 'center', width: '100%', padding: '0px 0px 100px 0px' }}>
            <div style={{ color: '#fff', fontWeight: 700, lineHeight: '58px', width: isMobcreen ? '100%' : '100%' }}>

              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', flexWrap: 'wrap' }}>
                <div style={{ display: 'block' }}>
                  <div style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>

                    <div style={{ display: 'flex', alignItems: 'center', color: '#f7ee23', fontSize: '1rem' }}>
                      <CalendarMonthIcon />
                      <div style={{ margin: '0px 10px' }}>28-03-2024</div>
                    </div>

                    <div style={{ display: 'flex', alignItems: 'center', color: '#f7ee23', fontSize: '1rem' }}>
                      <AccessTimeIcon />
                      <div style={{ margin: '0px 10px' }}>07:00 PM ONWARDS</div>
                    </div>

                    <div style={{ display: 'flex', alignItems: 'center', color: '#f7ee23', fontSize: '1rem' }}>
                      <ChevronRightIcon />
                      <div style={{ margin: '0px 10px' }}>ADMISSION: 16+</div>
                    </div>

                  </div>

                  <div style={{ display: 'flex', alignItems: 'center', color: '#f7ee23', fontSize: '1rem', lineHeight: 2 }}>
                    <LocationOnIcon />
                    <div style={{ margin: '0px 10px' }}>WAREHOUSE NO.1, MINA RASHED / DUBAI MARITIME CITY, DUBAI-UAE</div>
                  </div>
                </div>
                <div className='event-button' style={{ display: 'flex', alignItems: 'center', padding: '15px 0px 15px 0px' }}>
                  <Button className='inquiry-form-button btn btn-info'>
                    Book now
                  </Button>
                </div>


              </div>

            </div>
          </div>





          <div className="Lit-banner-card">

            <div className="row Banner-row">
              <div className={`col-md-3`}>
                <img
                  alt={'abfitness'}
                  src={`https://cdn.ebtida.com/1711624988629-post1.png.webp`}
                  style={{
                    transition: "all 0.5s",
                    width: '100%',
                    height: '260px',
                    objectFit: 'cover',
                    border: '1px solid #eee'
                  }}
                />
                <h1 className="Home-image-title" style={{ whiteSpace: 'break-spaces' }}>
                  {t('Ringside Rampage – The Launch – 4th June 2022')}
                </h1>
                <div style={{ color: '#aaa', fontSize: '14px' }}>June 4, 2022</div>
                <p style={{ color: '#aaa', whiteSpace: 'break-spaces', fontSize: '14px' }}>DUBAI MARITIME CITY HOSTS WORLD BOXING COUNCIL MIDDLE EAST CHAMPIONSHIPS Ringside Rampage returns with part IV; The Launch, on the 4th of June 2022 with</p>
              </div>
              <div className={`col-md-3`}>
                <img
                  alt={'abfitness'}
                  src={`https://cdn.ebtida.com/1711624999279-post2.jpg.webp`}
                  style={{
                    transition: "all 0.5s",
                    width: '100%',
                    height: '260px',
                    objectFit: 'cover',
                    border: '1px solid #eee'
                  }}
                />
                <h1 className="Home-image-title" style={{ whiteSpace: 'break-spaces' }}>
                  {t('Hexagon MMA France – 28th August 2022')}
                </h1>
                <div style={{ color: '#aaa', fontSize: '14px' }}>August 28, 2022</div>
                <p style={{ color: '#aaa', whiteSpace: 'break-spaces', fontSize: '14px' }}>Witness history at the HEXAGONE MMA World Championship in Dubai on August 28, 2022! This groundbreaking event features French and international fighters in a VIP</p>
              </div>
              <div className={`col-md-3`}>
                <img
                  alt={'abfitness'}
                  src={`https://cdn.ebtida.com/1711625009515-post3.jpg.webp`}
                  style={{
                    transition: "all 0.5s",
                    width: '100%',
                    height: '260px',
                    objectFit: 'cover',
                    border: '1px solid #eee'
                  }}
                />
                <h1 className="Home-image-title" style={{ whiteSpace: 'break-spaces' }}>
                  {t('Skechers Performance Run – 28th May 2023')}
                </h1>
                <div style={{ color: '#aaa', fontSize: '14px' }}>May 28, 2023</div>
                <p style={{ color: '#aaa', whiteSpace: 'break-spaces', fontSize: '14px' }}>Enter the SKECHERS Performance Run at Mina Rashid Marina on Sunday, 28 May, 6am start. The course takes you through the beautiful P&O Marina near</p>
              </div>
              <div className={`col-md-3`}>
                <img
                  alt={'abfitness'}
                  src={`https://cdn.ebtida.com/1711625017953-abfitness-mma-23rd.png`}
                  style={{
                    transition: "all 0.5s",
                    width: '100%',
                    height: '260px',
                    objectFit: 'cover',
                    border: '1px solid #eee'
                  }}
                />
                <h1 className="Home-image-title" style={{ whiteSpace: 'break-spaces' }}>
                  {t('MMA Global Event at AB Fitness Marina')}
                </h1>
                <div style={{ color: '#aaa', fontSize: '14px' }}>March 23, 2024</div>
                <p style={{ color: '#aaa', whiteSpace: 'break-spaces', fontSize: '14px' }}>
                  WITNESS THE CLASH OF TITANS AT AB FITNESS! MMA Global Series made a TRIUMPHANT return to Dubai on the 23rd of March 2024.
                </p>
              </div>
            </div>
          </div>
        </div>







      </div>



    </div>

  );
}

export default Event;
