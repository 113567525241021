import React, { useState, useEffect, Fragment } from 'react';
import { BrowserRouter as Router, Switch, Route, Link, useHistory, Redirect, useLocation, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Button, Table, Spinner, Accordion, Card, Dropdown, Badge, Modal, DropdownButton } from 'react-bootstrap'
import "antd/dist/antd.min.css";
import './index.scss';
import { useTranslation } from 'react-i18next'
import LocationOnIcon from '@mui/icons-material/LocationOn';
import EmailIcon from '@mui/icons-material/Email';
import SmartphoneIcon from '@mui/icons-material/Smartphone';
import { useSelector } from "react-redux";
import InstagramIcon from '@mui/icons-material/Instagram';

function Footer() {

  const { t } = useTranslation()
  const { languageCode } = useSelector((state) => state.auth);

 
  return (
    <div className="Footer-page" style={{ position: 'absolute', bottom: '0px', width: '100%', height: '0rem' }}> 
      <div className="Footer-menu-card1">
        <div className="container">
          <div className="Footer-menu-card">
            <div className="Footer-menu-item">
              <h3 style={{ padding: "5px", color: '#f7ee23' }}>{t('address')}</h3>
              <div className="Footer-menu-SubItem" style={{ display: 'flex', alignItems: 'center' }}>
                <LocationOnIcon className="footer-icons mobie-hide" />
                <div style={{ margin: '0 10px' }} dangerouslySetInnerHTML={{
                  __html: t('location')
                }}></div>
              </div>
              <div className="Footer-menu-SubItem">
                <SmartphoneIcon className="footer-icons mobie-hide" />
                <div style={{ margin: '0 10px' }}>{t('phone_numbersb')}</div>
              </div>
              <div className="Footer-menu-SubItem">
                <EmailIcon className="footer-icons mobie-hide" />
                <div style={{ margin: '0 10px' }}>{t('email_address')}</div>
              </div>
              <a href={'https://www.instagram.com/abfitness_uae'} className="Footer-menu-SubItem" target={'_blank'}>
                <InstagramIcon className="footer-icons mobie-hide" />
                <div style={{ margin: '0 10px' }}>{t('instagram')}</div>
              </a>
            </div>
          </div>

          <div className="Footer-menu-card">
            <div className="Footer-menu-item Footer-menu-bottom">
              <div className="Footer-menu-SubItem"><Link to="#">© {new Date().getFullYear()} {t('AB Fitness')} | {t('all_right_reserved')}</Link></div>
              <div className="Footer-menu-SubItem"><Link to={`/${languageCode}/privacy-policy`}>{t('privacy_policy')}</Link></div>
              <div className="Footer-menu-SubItem"><Link to={`/${languageCode}/terms-and-conditions`}>{t('terms_and_conditions')}</Link></div>
            </div>
          </div>

        </div>
      </div>
    </div>
  );
}

export default Footer;
