import React, { useState, useEffect, useRef, Fragment } from 'react';
import { BrowserRouter as Router, Switch, Route, Link, useHistory, Redirect, useLocation, useParams } from "react-router-dom";
import './index.scss';
import { useTranslation } from 'react-i18next'
import i18next from 'i18next'
import { useDispatch, useSelector } from "react-redux";
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import languages from '../../Layout/Pages/Languages';
import { removeToCart, removeUserToState, addSelectedSevenDayaPass, addToLanguage, addToOffer } from "../../Reducers/authReducer";
import { useMediaQuery } from 'react-responsive'
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/joy/Button';
import PermIdentityIcon from '@mui/icons-material/PermIdentity';
import Dropdown from '@mui/joy/Dropdown';
import Menu from '@mui/joy/Menu';
import MenuButton from '@mui/joy/MenuButton';
import MenuItem from '@mui/joy/MenuItem';
import { Modal } from 'react-bootstrap'
import Box from '@mui/joy/Box';

import Drawer from '@mui/joy/Drawer';
import ModalClose from '@mui/joy/ModalClose';


function Header() {
  const { userId, userToken, userEmail, languageCode, countryCode, isOffer } = useSelector((state) => state.auth);
  const isMobcreen = useMediaQuery({ query: '(min-width: 0px) and (max-width: 992px)' })
  const history = useHistory();
  const { t } = useTranslation()
  const dispatch = useDispatch();

 
  let fontFamilyForAll

  if (languageCode === 'ar') {
    fontFamilyForAll = 'GessTwoMedium, sans-serif'
  } else if (languageCode === 'urdu') {
    fontFamilyForAll = 'AlQalam Shakir'
  } else {
    fontFamilyForAll = 'Poppins, sans-serif'
  }


  function checkHeaderPath() {
    if (window.location.pathname === '/' || window.location.pathname === '/personal-trainers') {
      return true
    } else if (window.location.pathname === `/${languageCode}` || window.location.pathname === `/${languageCode}/personal-trainers`) {
      return true
    } else {
      return true
    }
  }


  const [getLogoSize, setLogoSize] = useState("180px");
  const [isScroll, setScroll] = useState(false);
  const listenScrollEvent = () => {
    window.scrollY > 15 ? setScroll(true) : setScroll(false);
    window.scrollY > 15 ? setLogoSize("62px") : setLogoSize("180px");
  };
  useEffect(() => {
    window.addEventListener("scroll", listenScrollEvent);
    return () => {
      window.removeEventListener("scroll", listenScrollEvent);
    };
  }, []);


  function menuList() {
    return (
      <>

        <Link
          style={{ display: 'block', color: window.location.pathname === `/${languageCode}/membership` || window.location.pathname === `/membership` ? '#fff' : '#eeed31' }}
          className="Header-waller-connect1"
          to={`/${languageCode}/membership`}>
          <span className="mobile-menu-item-size">{t('membership')}</span>
        </Link>

        <Link
          style={{ display: 'block', color: window.location.pathname === `/${languageCode}/personal-trainers` || window.location.pathname === `/personal-trainers` ? '#fff' : '#eeed31' }}
          className="Header-waller-connect1"
          to={`/${languageCode}/personal-trainers`}>
          <span className="mobile-menu-item-size">{t('trainers')}</span>
        </Link>

        <Link
          style={{ display: 'block', color: window.location.pathname === `/${languageCode}/gym` || window.location.pathname === `/gym` ? '#fff' : '#eeed31' }}
          className="Header-waller-connect1"
          to={`/${languageCode}/gym`}>
          <span className="mobile-menu-item-size">{t('Gym')}</span>
        </Link>

        <Link
          style={{ display: 'block', color: window.location.pathname === `/${languageCode}/classes` || window.location.pathname === `/classes` ? '#fff' : '#eeed31' }}
          className="Header-waller-connect1"
          to={`/${languageCode}/classes`}>
          <span className="mobile-menu-item-size">{t('Classes')}</span>
        </Link>

        <Link
          style={{ display: 'block', color: window.location.pathname === `/${languageCode}/events` || window.location.pathname === `/events` ? '#fff' : '#eeed31' }}
          className="Header-waller-connect1"
          to={`/${languageCode}/events`}>
          <span className="mobile-menu-item-size">{t('EVENTS')}</span>
        </Link>

      </>
    )
  }

  const [getMobMenu, setMobMenu] = useState(false);
  function getMobilemenu() {
    return (
      <div style={{
        width: '235px',
        position: 'fixed',
        left: 0,
        top: 0,
        zIndex: 9999,
        background: '#000',
        height: '100%',
        padding: '10px',
        transition: "all 0.5s",
        borderRight: '0.5px solid #f7ee23'
      }}>
        <div style={{ position: 'relative' }}>
          <IconButton
            style={{ position: 'absolute', right: 0, top: 0, zIndex: 1111 }}
            onClick={(() => setMobMenu(false))}>
            <CloseIcon style={{ color: '#fff' }} />
          </IconButton>
          {menuList()}
        </div>
      </div>
    )
  }


  const list = (anchor) => (
    <Box
      style={{ background: '#000', height: '100%' }}
      className="side-dialogue-section"
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <Link
        style={{ display: 'block', color: '#eeed31', padding: '6px 15px', textTransform: 'uppercase' }}
        className="Header-waller-connect1"
        to={`/${languageCode}/membership`}>
        <span className="mobile-menu-item-size">{t('membership')}</span>
      </Link>

      <Link
        style={{ display: 'block', color: '#eeed31', padding: '6px 15px', textTransform: 'uppercase' }}
        className="Header-waller-connect1"
        to={`/${languageCode}/personal-trainers`}>
        <span className="mobile-menu-item-size">{t('trainers')}</span>
      </Link>

      <Link
        style={{ display: 'block', color: '#eeed31', padding: '6px 15px', textTransform: 'uppercase' }}
        className="Header-waller-connect1"
        to={`/${languageCode}/gym`}>
        <span className="mobile-menu-item-size">{t('Gym')}</span>
      </Link>

      <Link
        style={{ display: 'block', color: '#eeed31', padding: '6px 15px', textTransform: 'uppercase' }}
        className="Header-waller-connect1"
        to={`/${languageCode}/classes`}>
        <span className="mobile-menu-item-size">{t('Classes')}</span>
      </Link>

      <Link
        style={{ display: 'block', color: '#eeed31', padding: '6px 15px', textTransform: 'uppercase' }}
        className="Header-waller-connect1"
        to={`/${languageCode}/events`}>
        <span className="mobile-menu-item-size">{t('EVENTS')}</span>
      </Link>
    </Box>
  );

  const [getcartDrawer, cartDrawer] = useState();
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setState({ ...state, [anchor]: open });
  };

  useEffect(() => {
    cartDrawer(getcartDrawer)
  }, [getcartDrawer]);

  const [getOffer, setOffer] = useState(false);

  function hideOffer() {
    setOffer(false)
    dispatch(addToOffer({ isOffer: false }));
  }
  useEffect(() => {
    if (!isOffer) {
      setOffer(true)
    }
  }, []);

  return (
    <>

      {isMobcreen &&
        <div style={{ display: 'flex', alignItems: 'center', width: '100%', position: 'fixed', bottom: 0, zIndex: 999 }}>

          <a href="https://api.whatsapp.com/send?phone=+97143418663&amp;text=Hi" target="_blank" style={{ width: '100%', display: 'flex', alignItems: 'center' }}>
            <Button
              style={{ width: '100%', borderRadius: 0, background: '#eee', color: '#000', height: '40px' }}>
              Chat
            </Button>
          </a>

          <a href="tel:+97143418663" style={{ width: '100%', display: 'flex', alignItems: 'center' }}>
            <Button
              style={{ width: '100%', borderRadius: 0, background: '#f7ee26', color: '#000', height: '40px' }}>
              Call Us
            </Button>
          </a>
        </div>
      }
      <div style={{ display: 'flex', alignItems: 'center', width: '100%', background: '#f7ee26', padding: '10px', height: '35px' }}>
        <div className='container'>
          <div
            onClick={(() => setOffer(true))}
            style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', cursor: 'pointer' }}>
            Epic Offer - Learn More
          </div>
        </div>
      </div>
      <Modal show={getOffer} onHide={hideOffer} centered animation={true}>
        <Modal.Body style={{ padding: '0px', position: 'relative', height: isMobcreen ? 'auto' : '498px', objectFit: 'contain' }}>
          <div
            onClick={hideOffer}
            style={{ position: 'absolute', right: '10px', top: '10px', background: '#eee', borderRadius: '100px', height: '30px', width: '30px', lineHeight: '30px', display: 'flex', alignItems: 'center', justifyContent: 'center', cursor: 'pointer' }}>
            <CloseIcon className="Header-close-modal-icon" />
          </div>
          <img src='https://cdn.ebtida.com/1712765218301-WhatsApp--abfitness.jpeg' style={{ width: '100%' }} />
        </Modal.Body>
      </Modal>
      <div className="Header-page"
        style={{
          position: 'fixed',
          // transition: "all 0.5s",
          background: isScroll ? '#000' : '#fff0',
          padding: '5px',
          top: isScroll ? '0px' : '35px'
        }}>
        {isMobcreen && getMobMenu &&
          getMobilemenu()
        }
        <div className="Header-menu">
          <div className="container">
            <div className={`Header-menu-list2 Header-menu-list`}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <div className="Header-menu-logoName">
                  <Link to={`/${languageCode}`}>
                    <LazyLoadImage
                      className={isScroll ? `scrolling-img` : `scrolling-img-rev`}
                      alt={'abfitness'}
                      title={'abfitness'}
                      effect="blur"
                      src={`https://d2wxcrmddmhll3.cloudfront.net/AB-Marina-Logo-min.png`}
                      width={checkHeaderPath() && !isMobcreen ? getLogoSize : '62px'}
                      style={{ transition: "all 0.5s" }}
                    />
                  </Link>
                </div>
              </div>
              <div className={`Header-menu-account`}>
                {!isMobcreen &&
                  <>
                    {menuList()}
                  </>
                }
              
                {isMobcreen &&
                  <div>
                    {[document.body.dir === 'rtl' ? 'right' : 'left'].map((anchor) => (
                      <IconButton key={anchor} onClick={toggleDrawer(anchor, true)}>
                        <MenuIcon style={{ color: '#eeed31' }} />
                      </IconButton>
                    ))}
                  </div>
                }
              </div>
            </div>
          </div>
        </div>
      </div>


      <React.Fragment>
        {[document.body.dir === 'rtl' ? 'right' : 'left'].map((anchor) => (
          <Drawer
            className="side-menu-section side-scroll-menu"
            key={anchor}
            anchor={anchor}
            open={state[anchor]}
            onClose={toggleDrawer(anchor, false)}
          >
            <div style={{ position: 'absolute', right: 0, top: 0, zIndex: 1, padding: '0px', textAlign: document.body.dir === 'rtl' ? 'left' : 'right' }}>
              <ModalClose id="close-icon" sx={{ position: 'initial', color: '#eeed31' }} />
            </div>
            <div style={{ display: 'flex', alignItems: 'center', width: '100%', padding: '6px 15px', background: '#000', height: '100%' }}>
              {list(anchor)}
            </div>
          </Drawer >
        ))
        }
      </React.Fragment >
    </>
  );
}

export default Header;
