const Benefits = {
  'en': [
    '120,000 sq. ft indoor gym',
    '500+ state-of-the-art equipment',
    'Infinity Pool',
    '125m indoor free-running track',
    'Nutrition and supplement station',
    'Sauna Room',
    'Personal training available',
    'Cycling Studio',
    'Physiotherapy Studio',
    'MMA arena and live fight events',
    'Amazing yacht views',
    'Open 24/7'
  ],
  'ar': [
    'صالة رياضية داخلية بمساحة 120.000 قدم مربع',
    'أكثر من 500 أجهزة رياضية حديثة',
    'حمام سباحة انفينيتي',
    'مضمار جري داخلي بطول 125 م',
    'محطة للأطعمة والمكملات الغذائية',
    'غرفة ساونا',
    'يتوفر التدريب الشخصي',
    'ستوديو الدراجة الهوائية',
    'استوديو العلاج الطبيعي',
    'حلبة لفنون القتال المختلطة والمباريات',
    'إطلالات خلابة على اليخوت',
    'مفتوح 24/7'
  ]
}

export default Benefits;