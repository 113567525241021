import React, { useState, useEffect, Fragment, useRef } from 'react';
import { BrowserRouter as Router, Switch, Route, Link, useHistory, Redirect, useLocation, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Button, Table, Spinner, Accordion, Card, Dropdown, Badge, Modal, DropdownButton } from 'react-bootstrap'
import "antd/dist/antd.min.css";
import './index.scss';
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { useTranslation } from 'react-i18next'
import { useSelector, useDispatch } from "react-redux";
import ReactPlayer from 'react-player'
import 'react-lazy-load-image-component/src/effects/blur.css';
import { useMediaQuery } from 'react-responsive'
import { useCreateInquiriesMutation } from '../../services/inquiries';
import ReCAPTCHA from "react-google-recaptcha";
import { addToInquiry } from "../../Reducers/authReducer";
import PhoneInput, { formatPhoneNumber, formatPhoneNumberIntl, isValidPhoneNumber, isPossiblePhoneNumber } from 'react-phone-number-input'
import Input from 'react-phone-number-input/input'
import SmartphoneIcon from '@mui/icons-material/Smartphone';
import { useParallax } from "react-scroll-parallax";


function Banner() {
  const history = useHistory();
  const location = useLocation();
  const { t } = useTranslation()
  const { register, handleSubmit, reset, formState: { errors } } = useForm();
  const { userId, userToken, userEmail, languageCode, isInquiry } = useSelector((state) => state.auth);
  const isMobcreen = useMediaQuery({ query: '(min-width: 0px) and (max-width: 992px)' })
  const dispatch = useDispatch();
  const [createinquiry, createresult] = useCreateInquiriesMutation()

  const [isVideoLoaded, setIsVideoLoaded] = useState(false);
  const onLoadedData = () => {
    setIsVideoLoaded(true);
  };



  const [getPhone, setPhone] = useState('')
  const [getPhoneError, setPhoneError] = useState(false)
  const [isAlready, setAlready] = useState(false)
  const recaptchaRef = React.useRef();

  async function createInquiryFrom(data) {
    const token = await recaptchaRef.current.executeAsync();
    recaptchaRef.current.reset();
    if (isValidPhoneNumber(getPhone)) {
      // if (isInquiry) {
      //   setAlready(true)
      // } else {
      createinquiry({
        name: data.name,
        email: data.email,
        message: data.message,
        phone: getPhone,
        language: 'en',
        country: 'AE',
        captcha_token: token,
        state: 'open',
        domain: 'abfitness.ebtida.com',
        to_email: 'abfittnessgym.uae@gmail.com',
        abfitness: true,
        domainEmail: 'help@ebtida.com'
      })
      // }
    } else {
      setPhoneError(true)
    }
  }

  useEffect(() => {
    if (createresult?.isSuccess) {
      reset()
      dispatch(addToInquiry({
        isInquiry: true,
      }));
    }
  }, [createresult]);


  const stockTimerId = useRef();
  React.useEffect(() => {
    stockTimerId.current = setInterval(() => {
      setAlready(false)
    }, 60000);
    return () => {
      clearInterval(stockTimerId.current);
    };
  }, []);


  const parallax = useParallax({
    // rotate: [0, 0],
    // translateY: ['-500px', '500px'],
    translateY: [0, 50],
    opacity: [1, 0],
    scale: [1.0, 1, 'easeOutCubic'],
    shouldAlwaysCompleteAnimation: true,
  });

  useEffect(() => {
    if (createresult?.isSuccess) {
      history.push(`${window.location.pathname}?freepass-thank-you`)
    }
  }, [createresult?.isSuccess])

  return (
    <div style={{ opacity: isVideoLoaded ? 1 : 1, position: 'relative', overflow: 'hidden' }} id="home">
      <div className='row' ref={parallax.ref} style={{ position: 'relative', background: '#000' }}>
        <ReactPlayer
          url={!isMobcreen ?
            'https://d2wxcrmddmhll3.cloudfront.net/Ab-Fitness-Website-Video.mp4' :
            'https://d2wxcrmddmhll3.cloudfront.net/1685994925681-tiktok_1.mp4'}
          playing={true}
          controls={false}
          loop={true}
          muted={true}
          playsinline={true}
          onReady={onLoadedData}
          width={'100%'}
          height={'100%'}
        />

        {!isMobcreen &&
          <div className="container HomeBanner-Text">
            <div className="HomeBanner-card" style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
              <div className="HomeBanner-Text-Card1" style={{
                color: '#fff',
                fontSize: '65px',
                fontWeight: 700,
                lineHeight: '58px',
                width: '60%'
              }}>
              </div>
              <div className="HomeBanner-Text-Card2" style={{ width: '40%' }}>
                <div
                  style={{
                    padding: '20px',
                    borderRadius: '10px',
                    textAlign: 'start',
                    background: '#00000075'
                  }}>
                  <img
                    className='free-day-img'
                    alt={'abfitness'}
                    src={`https://cdn.ebtida.com/1712770492998-Free-Pass-1.png`}
                    style={{ transition: "all 0.5s" }}
                  />

                  <>
                    {createresult?.error?.data?.success &&
                      <div style={{ textAlign: 'center', color: 'tomato', fontSize: '12px', webkitTextTtroke: '1px green', marginBottom: '10px' }} >
                        {createresult?.error?.data?.success}
                      </div>
                    }

                    {createresult?.error?.data?.error &&
                      <div style={{ textAlign: 'center', color: 'tomato', fontSize: '12px', webkitTextTtroke: '1px green', marginBottom: '10px' }} >
                        {createresult?.error?.data?.error}
                      </div>
                    }

                    {createresult?.data?.success &&
                      <div style={{ textAlign: 'center', color: 'green', fontSize: '12px', webkitTextTtroke: '1px green', marginBottom: '10px' }} >
                        {createresult?.data?.success}
                      </div>
                    }
                  </>
                  <form onSubmit={handleSubmit(createInquiryFrom)}>
                    <ReCAPTCHA
                      ref={recaptchaRef}
                      size="invisible"
                      sitekey="6LfD62EnAAAAAJ83k0BznR8m_cXInxBFjtTokspL"
                    />
                    <input
                      placeholder='Full Name'
                      className='form-control hp-input'
                      id="name"
                      aria-invalid={errors.name ? "true" : "false"}
                      {...register('name', { required: true })}
                    />
                    <input
                      placeholder='Email'
                      className='form-control hp-input'
                      id="email"
                      aria-invalid={errors.email ? "true" : "false"}
                      {...register('email', { required: true })}
                    />

                    {getPhoneError &&
                      <>
                        {getPhone && isValidPhoneNumber(getPhone) ? '' :
                          <small
                            style={{ color: 'tomato', textTransform: 'capitalize', fontSize: '12px', webkitTextTtroke: '1px tomato', marginBottom: '10px' }}>{'Invalid phone number'}
                          </small>
                        }
                      </>
                    }
                    <Input
                      className="hp-input"
                      country="AE"
                      international
                      withCountryCallingCode
                      value={getPhone}
                      onChange={setPhone} />
                    <input
                      placeholder='Message'
                      className='form-control hp-input'
                      id="message"
                      aria-invalid={errors.message ? "true" : "false"}
                      {...register('message', { required: true })}
                    />
                    <Button
                      style={{ height: '50px' }}
                      disabled={createresult.isLoading}
                      className="inquiry-form-button"
                      type="submit">
                      {createresult.isLoading ? t('loading') : t('submit')}
                    </Button>
                  </form>
                  <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', padding: '20px 0px 10px 0px' }}>
                    <SmartphoneIcon style={{ background: '#fcfcfc4a', borderRadius: '100px', padding: '4px', color: '#f7ee23', fontSize: '32px' }} />
                    <div style={{ margin: '0 10px', color: '#fff' }}>{t('phone_numbersb')}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        }
      </div>
    </div>
  );
}

export default Banner;
