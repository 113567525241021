import React, { useState, useEffect, Fragment } from 'react';
import { BrowserRouter as Router, Switch, Route, Link, useHistory, Redirect, useLocation, useParams } from "react-router-dom";
import "antd/dist/antd.min.css";
import Header from '../../Components/Header'
import Footer from '../../Pages/Footer'
import { useTranslation } from 'react-i18next'
import i18next from 'i18next'
import { useDispatch, useSelector } from "react-redux";
import { removeToCart, addToLanguage, removeUserToState } from "../../Reducers/authReducer";
import languages from './Languages';
import { ParallaxProvider } from 'react-scroll-parallax';

const { countries, zones } = require("moment-timezone/data/meta/latest.json");

function Pages(props) {
  const history = useHistory();
  const dispatch = useDispatch();
  const { t } = useTranslation()
  const { userId, userToken, userName, userEmail, languageCode, countryCode, isUser } = useSelector((state) => state.auth);

  const Components = props.Components;

  const timeZoneToCountry = {};
  Object.keys(zones).forEach(z => {
    const cityArr = z.split("/");
    const city = cityArr[cityArr.length - 1];
    timeZoneToCountry[city] = countries[zones[z].countries[0]].abbr;
  });
  let userCountryCode;
  if (Intl) {
    const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone || 'AE';
    const tzArr = userTimeZone?.split("/");
    const userCity = tzArr[tzArr.length - 1];
    userCountryCode = timeZoneToCountry[userCity];
  }

  const getLanguage = i18next?.language ? i18next?.language : 'en'
  const currentLanguage = languages?.find((l) => l.code === getLanguage)

  useEffect(() => {
    dispatch(addToLanguage({ languageCode: getLanguage, countryCode: userCountryCode }));
    document.body.dir = currentLanguage.dir || 'ltr'
    document.documentElement.lang = currentLanguage.lang || 'en'
  }, [currentLanguage])

  const lngSwipStyle = {
    textAlign: languageCode === 'ar' || languageCode === 'urdu' ? 'right' : 'left',
    fontFamily: languageCode === 'ar' || languageCode === 'urdu' ? 'GessTwoMedium, sans-serif' : 'Lato, sans-serif',
    display: 'block',
    minHeight: '100vh',
    position: 'relative',
    background: '#000',
  }


  return (
    <ParallaxProvider>

      <div style={lngSwipStyle}>
        {props.header && <Header />}

        <Components />

        {props.footer && <Footer />}
      </div>
    </ParallaxProvider>
  );
}

export default Pages;
