import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Provider } from 'react-redux';
import { store } from './app/store';

import i18next from 'i18next'
import { initReactI18next } from 'react-i18next'
import HttpApi from 'i18next-http-backend'
import LanguageDetector from 'i18next-browser-languagedetector'
import cookies from 'js-cookie'
import TagManager from 'react-gtm-module'

i18next
  .use(HttpApi)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    supportedLngs: ['en', 'ar', 'desi', 'urdu'],
    fallbackLng: 'en',
    debug: false,
    // Options for language detector
    detection: {
      order: ['path', 'cookie', 'htmlTag', 'localStorage', 'subdomain'],
      caches: ['cookie'],
      cookieMinutes: 43200,
    },
    // react: { useSuspense: false },
    backend: {
      loadPath: '/assets/locales/translation-{{lng}}.json',
    },
  })


const tagManagerArgs = {
  gtmId: 'GTM-NTF7MB6'
}
TagManager.initialize(tagManagerArgs)




ReactDOM.render(
  <Suspense fallback={''}>
    <Provider store={store}>
      <App />
    </Provider>
  </Suspense>,
  document.getElementById('root')
);
