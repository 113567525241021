import React, { useState, useEffect, Fragment } from 'react';
import { BrowserRouter as Router, Switch, Route, useHistory, Redirect, useLocation, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Button, Table, Spinner, Accordion, Card, Dropdown, Badge, Modal, DropdownButton } from 'react-bootstrap'
import "antd/dist/antd.min.css";
import './index.scss';
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from "react-redux";
import Trainers from '../../Components/Trainers';
import TrainersImages from '../../Components/TrainersImages';

import { useParallax } from "react-scroll-parallax";



function PersonalTrainers() {
  const { userId, userToken, userEmail, userName, isMember, isPaymentMethod, isCustomer, languageCode, subscription } = useSelector((state) => state.auth);
  const { register, handleSubmit, formState: { errors } } = useForm();
  const history = useHistory();
  const location = useLocation();
  const { t } = useTranslation()
  const dispatch = useDispatch();


  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      // behavior: "smooth"
    });
  }, [])


  const parallax = useParallax({
    // rotate: [0, 0],
    // translateY: ['-500px', '500px'],
    translateY: [0, 50],
    opacity: [1, 0],
    scale: [1.0, 1, 'easeOutCubic'],
    shouldAlwaysCompleteAnimation: true,
  });



  return (
    <div className="page-section">





      

      <div
        ref={parallax.ref}
        className="section"
        style={{
          background: '#040404',
          position: 'relative',
        }}>
        <div
          style={{
            backgroundImage: 'url(https://cdn.ebtida.com/1710876190277-GYM.webp)',
            backgroundPosition: 'center',
            backgroundSize: 'cover',
            height: '90vh'
          }}>
          <div style={{
            position: 'absolute',
            height: '100%',
            top: 0,
            right: 0,
            left: 0,
            background: '#000000ad'
          }}>
          </div>

          <div className='abolute-title'>
            <div className="container">
              Personal <br />Training
            </div>
          </div>
        </div>
      </div>

      <div style={{ background: '#000', position: 'relative', marginTop: '-125px' }}>
        <div className="container">
          <div className="row" style={{ justifyContent: 'center' }}>
            <div className="col-md-8">
              <div className="mobile-padding" style={{ padding: '50px', textAlign: 'center' }}>
                <h4 style={{ color: '#f7ee23', textTransform: 'uppercase' }}>
                  <strong>{t('Personal Trainers')}</strong>
                </h4>
                <p style={{ color: '#fff' }}>Our expert team of personal trainers, nutritionists and sports therapists will work with you to ensure you achieve the results you're looking for</p>
              </div>
            </div>
          </div>
          <Trainers />
          {/* <TrainersImages /> */}
        </div>
      </div>
      <br /><br />
    </div>

  );
}

export default PersonalTrainers;
