import React, { useState, useEffect, Fragment } from 'react';
import { BrowserRouter as Router, Switch, Route, Link, useHistory, Redirect, useLocation, useParams } from "react-router-dom";
import "antd/dist/antd.min.css";
import './index.scss';
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Navigation } from "swiper";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import 'react-lazy-load-image-component/src/effects/blur.css';
function CarouselProducts({ data, loading, length, title, languageCode, background, backgrounds, padding, getLanguage }) {

  return (
    <div className="recentlySection" style={{ background: backgrounds }}>

      <div className="row">
        <div className="col-md-12" dir={`${languageCode === 'ar' || languageCode === 'urdu' ? 'rtl' : 'ltr'}`}>
          <Swiper
            slidesPerView={2}
            spaceBetween={15}
            slidesPerGroup={2}
            loop={true}
            loopFillGroupWithBlank={false}
            pagination={{
              clickable: true,
            }}
            navigation={15 > 2 ? true : false}
            modules={[Pagination, Navigation]}
            className="mySwiper recentlySectionSwiper"
            style={{ background: background, padding: padding }}
          >
            <SwiperSlide className="Dashboard-books-card" key={1}>

              <div className="hover01 column">
                <div>
                  <figure className="img-list-card">
                    <img
                      alt={'abfitness'}
                      title={'abfitness'}
                      effect="blur"
                      width={'100%'}
                      className="img-list-img" style={{ transition: "all 0.5s" }} src={`https://d2wxcrmddmhll3.cloudfront.net/1685712935435-13.jpg`}
                    />
                  </figure>
                </div>
              </div>

            </SwiperSlide>
            
            <SwiperSlide className="Dashboard-books-card" key={3}>
              <div className="hover01 column">
                <div>
                  <figure className="img-list-card">
                    <img
                      alt={'abfitness'}
                      title={'abfitness'}
                      effect="blur"
                      width={'100%'}
                      className="img-list-img" style={{ transition: "all 0.5s" }} src={`https://d2wxcrmddmhll3.cloudfront.net/1685712946579-15.jpg`}
                    />
                  </figure>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide className="Dashboard-books-card" key={4}>
              <div className="hover01 column">
                <div>
                  <figure className="img-list-card">
                    <img
                      alt={'abfitness'}
                      title={'abfitness'}
                      effect="blur"
                      width={'100%'}
                      className="img-list-img" style={{ transition: "all 0.5s" }} src={`https://d2wxcrmddmhll3.cloudfront.net/1685712950928-12.jpg`}
                    />
                  </figure>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide className="Dashboard-books-card" key={5}>
              <div className="hover01 column">
                <div>
                  <figure className="img-list-card">
                    <img
                      alt={'abfitness'}
                      title={'abfitness'}
                      effect="blur"
                      width={'100%'}
                      className="img-list-img" style={{ transition: "all 0.5s" }} src={`https://d2wxcrmddmhll3.cloudfront.net/1685712962979-11.jpg`}
                    />
                  </figure>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide className="Dashboard-books-card" key={8}>
              <div className="hover01 column">
                <div>
                  <figure className="img-list-card">
                    <img
                      alt={'abfitness'}
                      title={'abfitness'}
                      effect="blur"
                      width={'100%'}
                      className="img-list-img" style={{ transition: "all 0.5s" }} src={`https://d2wxcrmddmhll3.cloudfront.net/1685712982263-8.jpg`}
                    />
                  </figure>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide className="Dashboard-books-card" key={6}>
              <div className="hover01 column">
                <div>
                  <figure className="img-list-card">
                    <img
                      alt={'abfitness'}
                      title={'abfitness'}
                      effect="blur"
                      width={'100%'}
                      className="img-list-img" style={{ transition: "all 0.5s" }} src={`https://d2wxcrmddmhll3.cloudfront.net/1685712972377-10.jpg`}
                    />
                  </figure>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide className="Dashboard-books-card" key={7}>
              <div className="hover01 column">
                <div>
                  <figure className="img-list-card">
                    <img
                      alt={'abfitness'}
                      title={'abfitness'}
                      effect="blur"
                      width={'100%'}
                      className="img-list-img" style={{ transition: "all 0.5s" }} src={`https://d2wxcrmddmhll3.cloudfront.net/1685712977264-9.jpg`}
                    />
                  </figure>
                </div>
              </div>
            </SwiperSlide>
           
            <SwiperSlide className="Dashboard-books-card" key={9}>
              <div className="hover01 column">
                <div>
                  <figure className="img-list-card">
                    <img
                      alt={'abfitness'}
                      title={'abfitness'}
                      effect="blur"
                      width={'100%'}
                      className="img-list-img" style={{ transition: "all 0.5s" }} src={`https://d2wxcrmddmhll3.cloudfront.net/1685712987875-07.jpg`}
                    />
                  </figure>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide className="Dashboard-books-card" key={10}>
              <div className="hover01 column">
                <div>
                  <figure className="img-list-card">
                    <img
                      alt={'abfitness'}
                      title={'abfitness'}
                      effect="blur"
                      width={'100%'}
                      className="img-list-img" style={{ transition: "all 0.5s" }} src={`https://d2wxcrmddmhll3.cloudfront.net/1685712993632-06.jpg`}
                    />
                  </figure>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide className="Dashboard-books-card" key={11}>
              <div className="hover01 column">
                <div>
                  <figure className="img-list-card">
                    <img
                      alt={'abfitness'}
                      title={'abfitness'}
                      effect="blur"
                      width={'100%'}
                      className="img-list-img" style={{ transition: "all 0.5s" }} src={`https://d2wxcrmddmhll3.cloudfront.net/1685713106420-5(2).jpg`}
                    />
                  </figure>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide className="Dashboard-books-card" key={12}>
              <div className="hover01 column">
                <div>
                  <figure className="img-list-card">
                    <img
                      alt={'abfitness'}
                      title={'abfitness'}
                      effect="blur"
                      width={'100%'}
                      className="img-list-img" style={{ transition: "all 0.5s" }} src={`https://d2wxcrmddmhll3.cloudfront.net/1685713112314-4(4).jpg`}
                    />
                  </figure>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide className="Dashboard-books-card" key={2}>
              <div className="hover01 column">
                <div>
                  <figure className="img-list-card">
                    <img
                      alt={'abfitness'}
                      title={'abfitness'}
                      effect="blur"
                      width={'100%'}
                      className="img-list-img" style={{ transition: "all 0.5s" }} src={`https://d2wxcrmddmhll3.cloudfront.net/1685712941266-14.jpg`}
                    />
                  </figure>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide className="Dashboard-books-card" key={13}>
              <div className="hover01 column">
                <div>
                  <figure className="img-list-card">
                    <img
                      alt={'abfitness'}
                      title={'abfitness'}
                      effect="blur"
                      width={'100%'}
                      className="img-list-img" style={{ transition: "all 0.5s" }} src={`https://d2wxcrmddmhll3.cloudfront.net/1685713117667-1(3).jpg`}
                    />
                  </figure>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide className="Dashboard-books-card" key={14}>
              <div className="hover01 column">
                <div>
                  <figure className="img-list-card">
                    <img
                      alt={'abfitness'}
                      title={'abfitness'}
                      effect="blur"
                      width={'100%'}
                      className="img-list-img" style={{ transition: "all 0.5s" }} src={`https://d2wxcrmddmhll3.cloudfront.net/1685713123247-2(5).jpg`}
                    />
                  </figure>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide className="Dashboard-books-card" key={15}>
              <div className="hover01 column">
                <div>
                  <figure className="img-list-card">
                    <img
                      alt={'abfitness'}
                      title={'abfitness'}
                      effect="blur"
                      width={'100%'}
                      className="img-list-img" style={{ transition: "all 0.5s" }} src={`https://d2wxcrmddmhll3.cloudfront.net/1685713147873-03.jpg`}
                    />
                  </figure>
                </div>
              </div>
            </SwiperSlide>
          </Swiper>
        </div>
      </div>
    </div>

  );
}

export default CarouselProducts;
