import React, { useState, useEffect, Fragment } from 'react';
import { BrowserRouter as Router, Switch, Route, useHistory, Redirect, useLocation, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Button, Table, Spinner, Accordion, Card, Dropdown, Badge, Modal, DropdownButton } from 'react-bootstrap'
import "antd/dist/antd.min.css";
import './index.scss';
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from "react-redux";
import { useParallax } from "react-scroll-parallax";


function Classes() {
  const { userId, userToken, userEmail, userName, isMember, isPaymentMethod, isCustomer, languageCode, subscription } = useSelector((state) => state.auth);
  const { register, handleSubmit, formState: { errors } } = useForm();
  const history = useHistory();
  const location = useLocation();
  const { t } = useTranslation()
  const dispatch = useDispatch();


  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      // behavior: "smooth"
    });
  }, [])

  const parallax = useParallax({
    // rotate: [0, 0],
    // translateY: ['-500px', '500px'],
    translateY: [0, 50],
    opacity: [1, 0],
    scale: [1.0, 1, 'easeOutCubic'],
    shouldAlwaysCompleteAnimation: true,
  });



  return (
    <div className="page-section">

      <div
        ref={parallax.ref}
        className="section"
        style={{
          background: '#040404',
          position: 'relative',
        }}>
        <div
          style={{
            backgroundImage: 'url(https://cdn.ebtida.com/1710876199675-GroupClasses.webp)',
            backgroundPosition: 'center',
            backgroundSize: 'cover',
            height: '500px'
          }}>
          <div style={{
            position: 'absolute',
            height: '100%',
            top: 0,
            right: 0,
            left: 0,
            background: '#000000ad'
          }}>
          </div>

          <div className='class-title'>
            <div className="container">
              Group Classes
            </div>
          </div>
        </div>
      </div>

      <div style={{ background: '#000', position: 'relative' }}>
        <div className="container">

          <div className="row" style={{ justifyContent: 'center' }}>
            <div className="col-md-8">

              <div style={{ marginTop: '-64px' }}>
                <img src='/trainers/ramadan-timing.jpeg' style={{ width: '100%' }} />
              </div>
              <br /><br />

              <div className="mobile-padding" style={{ padding: '50px', textAlign: 'center' }}>
                <p style={{ color: '#fff' }}>Group fitness classes are a popular way for people to engage in structured exercise while enjoying a sense of community and motivation. These classes are typically led by trained instructors who guide participants through a variety of exercises and activities.</p>
                <p style={{ color: '#f7ee23' }}>Here are some common types of group fitness classes you might find at a gym:</p>
              </div>
            </div>
          </div>

          <div className="row">
            {/* <div className="col-md-6">
              <div
                className="mobile-padding box-classes"
                style={{ padding: '50px', textAlign: 'center', background: '#1a1a1a', borderRadius: '10px', border: '1px solid #eeed3233', overflow: 'auto', whiteSpace: 'normal' }}>
                <h4 style={{ color: '#f7ee23', textTransform: 'uppercase', marginBottom: '20px' }}>
                  <strong>{t('Monday')}</strong>
                </h4>
                <div className='box-classes-content' style={{ height: '215px' }}>
                  <table className='class-table' style={{ width: '100%' }}>
                    <thead>
                      <tr>
                        <th>Class</th>
                        <th>Coach</th>
                        <th>Time</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>RPM CLASS </td>
                        <td> COACH OSAMA </td>
                        <td> 6-7 PM</td>
                      </tr>
                      <tr>
                        <td>VINAYASA CLASS </td>
                        <td> COACH SONALI </td>
                        <td> 7-8 PM</td>
                      </tr>
                      <tr>
                        <td>BOXING CLASS </td>
                        <td> COACH NUAY </td>
                        <td> 7-8 PM </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div> */}
            {/* <div className="col-md-6">
              <div className="mobile-padding"
                style={{ padding: '50px', textAlign: 'center', background: '#1a1a1a', borderRadius: '10px', border: '1px solid #eeed3233', overflow: 'auto', whiteSpace: 'normal' }}>
                <h4 style={{ color: '#f7ee23', textTransform: 'uppercase', marginBottom: '20px' }}>
                  <strong>{t('Tuesday')}</strong>
                </h4>
                <div style={{ height: '215px' }}>
                  <table className='class-table' style={{ width: '100%' }}>
                    <thead>
                      <tr>
                        <th>Class</th>
                        <th>Coach</th>
                        <th>Time</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>CIRCUIT CLASS </td>
                        <td> COACH NOR </td>
                        <td> 6-7 PM</td>
                      </tr>
                      <tr>
                        <td>BODY PUMP CLASS </td>
                        <td> COACH NOR </td>
                        <td> 7-8 PM</td>
                      </tr>
                      <tr>
                        <td>BOXING CLASS </td>
                        <td> COACH NUAY </td>
                        <td> 7-8 PM </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div> */}
          </div>
          <br />
          <div className="row">
            {/* <div className="col-md-6">
              <div
                className="mobile-padding"
                style={{ padding: '50px', textAlign: 'center', background: '#1a1a1a', borderRadius: '10px', border: '1px solid #eeed3233', overflow: 'auto', whiteSpace: 'normal' }}>
                <h4 style={{ color: '#f7ee23', textTransform: 'uppercase', marginBottom: '20px' }}>
                  <strong>{t('Wednesday')}</strong>
                </h4>
                <div style={{ height: '215px' }}>
                  <table className='class-table' style={{ width: '100%' }}>
                    <thead>
                      <tr>
                        <th>Class</th>
                        <th>Coach</th>
                        <th>Time</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>HATHA YOGA CLASS </td>
                        <td> COACH SONALI </td>
                        <td> 6-7 PM</td>
                      </tr>
                      <tr>
                        <td>RPM CLASS </td>
                        <td> COACH OSAMA </td>
                        <td> 6-7 PM</td>
                      </tr>
                      <tr>
                        <td>CIRCUIT CLASS </td>
                        <td> COACH NOR </td>
                        <td> 6-7 PM</td>
                      </tr>
                      <tr>
                        <th>BODY PUMP CLASS </th>
                        <th> COACH NOR </th>
                        <th> 7-8 PM</th>
                      </tr>
                      <tr>
                        <th>MUAY THAI CLASS  </th>
                        <th>  COACH NUAY  </th>
                        <th>  7-8 PM </th>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div> */}
            {/* <div className="col-md-6">
              <div className="mobile-padding"
                style={{ padding: '50px', textAlign: 'center', background: '#1a1a1a', borderRadius: '10px', border: '1px solid #eeed3233', overflow: 'auto', whiteSpace: 'normal' }}>
                <h4 style={{ color: '#f7ee23', textTransform: 'uppercase', marginBottom: '20px' }}>
                  <strong>{t('Thursday')}</strong>
                </h4>
                <div style={{ height: '215px' }}>
                  <table className='class-table' style={{ width: '100%' }}>
                    <thead>
                      <tr>
                        <th>Class</th>
                        <th>Coach</th>
                        <th>Time</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>BOXING CLASS </td>
                        <td> COACH NUAY </td>
                        <td> 7-8 PM </td>
                      </tr>
                      <tr>
                        <td>POWER YOGA CLASS  </td>
                        <td>  COACH NOR  </td>
                        <td>  7-0 AM</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div> */}
          </div>

          <br />
          <div className="row">

            {/* <div className="col-md-6">
              <div className="mobile-padding"
                style={{ padding: '50px', textAlign: 'center', background: '#1a1a1a', borderRadius: '10px', border: '1px solid #eeed3233', overflow: 'auto', whiteSpace: 'normal' }}>
                <h4 style={{ color: '#f7ee23', textTransform: 'uppercase', marginBottom: '20px' }}>
                  <strong>{t('Friday')}</strong>
                </h4>
                <div style={{ height: '215px' }}>
                  <table className='class-table' style={{ width: '100%' }}>
                    <thead>
                      <tr>
                        <th>Class</th>
                        <th>Coach</th>
                        <th>Time</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>VINAYASA CLASS  </td>
                        <td>  COACH SONALI  </td>
                        <td>  6-7 PM </td>
                      </tr>
                      <tr>
                        <td>CIRCUIT CLASS </td>
                        <td>  COACH NOR  </td>
                        <td>  6-7 PM </td>
                      </tr>
                      <tr>
                        <td>RPM CLASS </td>
                        <td>  COACH ASHRAF  </td>
                        <td> 7-8 PM </td>
                      </tr>
                      <tr>
                        <td>BODY PUMP CLASS  </td>
                        <td>  COACH NOR </td>
                        <td>  7-8 PM  </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </div>
      <br /><br />
    </div>

  );
}

export default Classes;
