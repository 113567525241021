import React, { useState, useEffect, Fragment } from 'react';
import { BrowserRouter as Router, Switch, Route, Link, useHistory, Redirect, useLocation, useParams } from "react-router-dom";
import "antd/dist/antd.min.css";
import './index.scss';
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Navigation } from "swiper";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { useTranslation } from 'react-i18next'
import { useSelector } from "react-redux";
import { img } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
function CarouselEvents({ data, loading, length, title, languageCode, background, backgrounds, padding, getLanguage }) {
  const history = useHistory();
  const location = useLocation();
  const { t } = useTranslation()

  const { userId, userToken, userEmail, userName, isMember, isPaymentMethod, isCustomer } = useSelector((state) => state.auth);


  return (
    <div className="recentlySection" id="media" style={{ background: backgrounds }}>

      <div className="Dashboard-SEC-T" style={{ textAlign: 'center', textTransform: 'uppercase' }}>
        <div>{title}</div>
      </div>
      <div className="row">
        <div className="col-md-12" dir={`${languageCode === 'ar' || languageCode === 'urdu' ? 'rtl' : 'ltr'}`}>

          <Swiper
            slidesPerView={3}
            spaceBetween={15}
            slidesPerGroup={1}
            loop={true}
            loopFillGroupWithBlank={false}
            pagination={{
              clickable: true,
            }}
            navigation={15 > 3 ? true : false}
            modules={[Pagination, Navigation]}
            className="mySwiper recentlySectionSwiper"
            style={{ background: background, padding: padding }}
          >

            <SwiperSlide className="Dashboard-books-card" key={1}>

              <div className="hover01 column">
                <div>
                  <figure className="img-list-card">
                    <img
                      alt={'abfitness'}
                      title={'abfitness'}
                      effect="blur"
                      width={'100%'}
                      className="img-list-img" style={{ transition: "all 0.5s" }} src={`https://dj70nht8amdum.cloudfront.net/abfitness/events/ei1.jpeg`}
                    />
                  </figure>
                </div>
              </div>

            </SwiperSlide>
            <SwiperSlide className="Dashboard-books-card" key={2}>
              <div className="hover01 column">
                <div>
                  <figure className="img-list-card">
                    <img
                      alt={'abfitness'}
                      title={'abfitness'}
                      effect="blur"
                      width={'100%'}
                      className="img-list-img" style={{ transition: "all 0.5s" }} src={`https://dj70nht8amdum.cloudfront.net/abfitness/events/ei2.jpeg`}
                    />
                  </figure>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide className="Dashboard-books-card" key={3}>
              <div className="hover01 column">
                <div>
                  <figure className="img-list-card">
                    <img
                      alt={'abfitness'}
                      title={'abfitness'}
                      effect="blur"
                      width={'100%'}
                      className="img-list-img" style={{ transition: "all 0.5s" }} src={`https://dj70nht8amdum.cloudfront.net/abfitness/events/ei3.jpeg`}
                    />
                  </figure>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide className="Dashboard-books-card" key={4}>
              <div className="hover01 column">
                <div>
                  <figure className="img-list-card">
                    <img
                      alt={'abfitness'}
                      title={'abfitness'}
                      effect="blur"
                      width={'100%'}
                      className="img-list-img" style={{ transition: "all 0.5s" }} src={`https://dj70nht8amdum.cloudfront.net/abfitness/events/ei4.jpeg`}
                    />
                  </figure>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide className="Dashboard-books-card" key={5}>
              <div className="hover01 column">
                <div>
                  <figure className="img-list-card">
                    <img
                      alt={'abfitness'}
                      title={'abfitness'}
                      effect="blur"
                      width={'100%'}
                      className="img-list-img" style={{ transition: "all 0.5s" }} src={`https://dj70nht8amdum.cloudfront.net/abfitness/events/ei5.jpeg`}
                    />
                  </figure>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide className="Dashboard-books-card" key={6}>
              <div className="hover01 column">
                <div>
                  <figure className="img-list-card">
                    <img
                      alt={'abfitness'}
                      title={'abfitness'}
                      effect="blur"
                      width={'100%'}
                      className="img-list-img" style={{ transition: "all 0.5s" }} src={`https://dj70nht8amdum.cloudfront.net/abfitness/events/ei15.jpeg`}
                    />
                  </figure>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide className="Dashboard-books-card" key={7}>
              <div className="hover01 column">
                <div>
                  <figure className="img-list-card">
                    <img
                      alt={'abfitness'}
                      title={'abfitness'}
                      effect="blur"
                      width={'100%'}
                      className="img-list-img" style={{ transition: "all 0.5s" }} src={`https://dj70nht8amdum.cloudfront.net/abfitness/events/ei6.jpeg`}
                    />
                  </figure>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide className="Dashboard-books-card" key={8}>
              <div className="hover01 column">
                <div>
                  <figure className="img-list-card">
                    <img
                      alt={'abfitness'}
                      title={'abfitness'}
                      effect="blur"
                      width={'100%'}
                      className="img-list-img" style={{ transition: "all 0.5s" }} src={`https://dj70nht8amdum.cloudfront.net/abfitness/events/ei7.jpeg`}
                    />
                  </figure>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide className="Dashboard-books-card" key={9}>
              <div className="hover01 column">
                <div>
                  <figure className="img-list-card">
                    <img
                      alt={'abfitness'}
                      title={'abfitness'}
                      effect="blur"
                      width={'100%'}
                      className="img-list-img" style={{ transition: "all 0.5s" }} src={`https://dj70nht8amdum.cloudfront.net/abfitness/events/ei8.jpeg`}
                    />
                  </figure>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide className="Dashboard-books-card" key={10}>
              <div className="hover01 column">
                <div>
                  <figure className="img-list-card">
                    <img
                      alt={'abfitness'}
                      title={'abfitness'}
                      effect="blur"
                      width={'100%'}
                      className="img-list-img" style={{ transition: "all 0.5s" }} src={`https://dj70nht8amdum.cloudfront.net/abfitness/events/ei9.jpeg`}
                    />
                  </figure>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide className="Dashboard-books-card" key={11}>
              <div className="hover01 column">
                <div>
                  <figure className="img-list-card">
                    <img
                      alt={'abfitness'}
                      title={'abfitness'}
                      effect="blur"
                      width={'100%'}
                      className="img-list-img" style={{ transition: "all 0.5s" }} src={`https://dj70nht8amdum.cloudfront.net/abfitness/events/ei10.jpeg`}
                    />
                  </figure>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide className="Dashboard-books-card" key={12}>
              <div className="hover01 column">
                <div>
                  <figure className="img-list-card">
                    <img
                      alt={'abfitness'}
                      title={'abfitness'}
                      effect="blur"
                      width={'100%'}
                      className="img-list-img" style={{ transition: "all 0.5s" }} src={`https://dj70nht8amdum.cloudfront.net/abfitness/events/ei11.jpeg`}
                    />
                  </figure>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide className="Dashboard-books-card" key={13}>
              <div className="hover01 column">
                <div>
                  <figure className="img-list-card">
                    <img
                      alt={'abfitness'}
                      title={'abfitness'}
                      effect="blur"
                      width={'100%'}
                      className="img-list-img" style={{ transition: "all 0.5s" }} src={`https://dj70nht8amdum.cloudfront.net/abfitness/events/ei12.jpeg`}
                    />
                  </figure>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide className="Dashboard-books-card" key={14}>
              <div className="hover01 column">
                <div>
                  <figure className="img-list-card">
                    <img
                      alt={'abfitness'}
                      title={'abfitness'}
                      effect="blur"
                      width={'100%'}
                      className="img-list-img" style={{ transition: "all 0.5s" }} src={`https://dj70nht8amdum.cloudfront.net/abfitness/events/ei13.jpeg`}
                    />
                  </figure>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide className="Dashboard-books-card" key={15}>
              <div className="hover01 column">
                <div>
                  <figure className="img-list-card">
                    <img
                      alt={'abfitness'}
                      title={'abfitness'}
                      effect="blur"
                      width={'100%'}
                      className="img-list-img" style={{ transition: "all 0.5s" }} src={`https://dj70nht8amdum.cloudfront.net/abfitness/events/ei4.jpeg`}
                    />
                  </figure>
                </div>
              </div>
            </SwiperSlide>
          </Swiper>
        </div>
      </div>
      <br /><br />
    </div>



  );
}

export default CarouselEvents;
