const languages = [
  {
    code: 'desi',
    name: 'Desi',
    country_code: 'desi',
    lang: 'desi',
  },
  {
    code: 'en',
    name: 'English',
    country_code: 'gb',
    lang: 'en',
  },
  {
    code: 'ar',
    name: 'العربية',
    dir: 'rtl',
    country_code: 'sa',
    lang: 'ar',
  },
  {
    code: 'urdu',
    name: 'اردو',
    dir: 'rtl',
    country_code: 'pk',
    lang: 'urdu',
  },
]

export default languages