import React, { useState, useEffect, Fragment } from 'react';
import { BrowserRouter as Router, Switch, Route, Link, useHistory, Redirect, useLocation, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Button, Table, Spinner, Accordion, Card, Badge, Modal, DropdownButton } from 'react-bootstrap'
import "antd/dist/antd.min.css";
import './index.scss';
import { useTranslation } from 'react-i18next'
import 'react-loading-skeleton/dist/skeleton.css'
import { useSelector } from "react-redux";
function PrivacyPolicy() {
  const { userId, userToken, userEmail, userName, isMember, isPaymentMethod, languageCode } = useSelector((state) => state.auth);
  const history = useHistory();
  const location = useLocation();
  const { t } = useTranslation()
  const [getSearchId, setSearchId] = useState("")

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      // behavior: "smooth"
    });
  }, [])

  return (
    <div className="statisc-page">
<br /><br /><br /><br /><br />
      <div className="container static-container">
        <div className="row static-row">
          <div className="col-md-8">
            <div className="static-container-card" style={{ textAlign: 'left', padding: '20px', background: '#fff0' }}>
              <h3 style={{ padding: '40px 0px 20px 0px' }}>
                {t('privacy_policy')}
                <br />
              </h3>
              <p className="terms-text">AB Fitness Dubai Marina will never knowingly pass on details transmitted through this website, by phone, email, post, fax or in person to unauthorised third parties. In addition to contact you may make with AB Fitness Dubai Marina; we would like you to note that we also record visitor activity on our website in the form of anonymous data collection which helps us to improve our website and identify potential problems. Under normal circumstances, it is impossible to track an IP address and other information collected to an individual or organisation unless criminal activity occurs on our website. In such a case, we must pass on details to third legal parties, policing organisations in the United Arab Emirates or worldwide, or to compile our own legal claims. Within our privacy policy, we pledge to act within the The Personal Data Protection Law, Federal Decree Law No. 45 of 2021 f the UAE. Any breaches reported to us will be investigated appropriately and if necessary, we will contact relevant government and legal bodies. Please report all complaints and concerns in writing to E-mail: info@abfitness.ae</p>

              <p className="terms-text">At AB Fitness Dubai Marina, we are always dedicated to protecting your rights of privacy, our privacy policy and implementation of our privacy policy meets the requirements of the The Personal Data Protection Law, and includes information in relation to how we may contact you, and handle your information. If you have any queries about this statement, please contact us at info@abfitness.ae</p>


              <h4><strong>When do we collect information?</strong></h4>
              <p className="terms-text">We will obtain personal information from you when you complete an online Membership Agreement, we may also collect non-identifiable information about how you browse our website, using traffic tracking software, we may also collect your details by emails sent to AB Fitness Dubai Marina or email newsletter / sign up information forms or club viewing cards during club tours/visits.</p>



              <h4><strong>What information do we collect?</strong></h4>
              <p className="terms-text">The types of information we collect includes name, date of birth, e-mail address, postal address, telephone number and your credit card details. Note that we do not store your credit card details on our web servers - this is securely stored with a third party, bank approved firm, whom we have contracted to collect payments in a passive manner.</p>
              <h4><strong>How do we use this information?</strong></h4>
              <p className="terms-text">We will use your personal information to provide you with the services, products or information that you have requested and for administration purposes. We may need to share your information with our service providers, associated organisations and agents for these purposes.</p>
              <h4><strong>Do we use cookies?</strong></h4>
              <p className="terms-text">AB Fitness Dubai Marina uses cookies to ensure that you can interact with our web site successfully, to identify you when you visit our web sites and to keep track of your browsing patterns. The use of cookies does not give us access to the rest of your computer. If you want to prevent our cookies being stored on your computer in future, you may do so by referring to your internet browser's instructions. Please note however that if you disable our cookies, you may not be able to access certain services or facilities on our sites and your use of our sites may be restricted - in addition, certain website elements on our website may be degraded and not legally secured (for example online payments).</p>
              <h4><strong>How do we protect personal information?</strong></h4>
              <p className="terms-text">We use a secure server for hosting our website, and also take appropriate measures to ensure that the information disclosed to us is kept secure, accurate and up to date and kept only for so long as is necessary for the purposes for which it is used.</p>
              <h4><strong>Will we disclose the information we collect to outside parties?</strong></h4>
              <p className="terms-text">As indicated above, we may pass your information to our service providers, agents and associated organisations. We may also need to disclose your information if required to do so by law, in line with the UAE privacy and data protection laws.</p>
              <h4><strong>Your consent</strong></h4>
              <p className="terms-text">By providing us with your personal data, including sensitive personal data such as on your health, you consent to the collection and use of any information you provide in accordance with the above purposes and this privacy statement. You also consent to our transferring your information to countries or jurisdictions which do not provide the same level of data protection as the United Arab Emirates, if necessary for the above purposes. If we do make such a transfer, we will, if appropriate, put a contract in place to ensure your information is protected. AB Fitness Dubai Marina, however observes your legal rights as per in the United Arab Emirates and cannot be held responsible for breaches of your constitutional, federal or other local / national rights.</p>

              <h4><strong>Right of access</strong></h4>
              <p className="terms-text">You have the right to ask for a copy of the information we hold about you (for which we may charge a small fee) and to have any inaccuracies in your information corrected.</p>
              <h4><strong>Changes</strong></h4>
              <p className="terms-text">If your personal details change, please help us to keep your information up to date by updating your profile on the AB Fitness Dubai Marina website, by notifying us at our address or at reception in our club. We reserve the right to amend this privacy statement. If we do so, we will post notice of the change on our website and you will be deemed to have accepted such changes.</p>

            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PrivacyPolicy;

