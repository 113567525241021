import React, { useState, useEffect, Fragment } from 'react';
import { BrowserRouter as Router, Switch, Route, Link, useHistory, Redirect, useLocation, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Button, Table, Spinner, Accordion, Card, Badge, Modal, DropdownButton } from 'react-bootstrap'
import "antd/dist/antd.min.css";
import './index.scss';
import { useTranslation } from 'react-i18next'
import 'react-loading-skeleton/dist/skeleton.css'
import { useSelector } from "react-redux";
function TermsConditions() {
  const { userId, userToken, userEmail, userName, isMember, isPaymentMethod, languageCode } = useSelector((state) => state.auth);
  const history = useHistory();
  const location = useLocation();
  const { t } = useTranslation()
  const [getSearchId, setSearchId] = useState("")

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      // behavior: "smooth"
    });
  }, [])

  return (
    <div className="statisc-page">
      <br /><br /><br /><br /><br />
      <div className="container static-container">
        <div className="row static-row">
          <div className="col-md-8">
            <div className="static-container-card" style={{ textAlign: 'left', padding: '20px', background: '#fff0' }}>
              <h3 style={{ padding: '40px 0px 20px 0px' }}>
                {t('terms_and_condition')}
                <br />
              </h3>
              <p className="terms-text">All of the membership rules contained herein apply equally to members, temporary members and guests alike.</p>
              <h4><strong>Gym</strong></h4>
              <p className="terms-text">All reference to the ‘Gym’ refers to AB Fitness Dubai Marina, its staff, employees, sub-contractors, agents and representatives. Facilities refer to the gymnasium, showers, saunas, changing rooms and fitness studios where applicable.</p>
              <p className="terms-text">Members of the gym are bound, as a condition of membership, to comply with the rules set out below. This is to ensure that the facilities are properly and safely used and that all members have full advantage of them without interfering with the enjoyment of others.</p>
              <h4><strong>Membership</strong></h4>
              <p className="terms-text">All memberships are Non-Transferable & Non-Refundable.</p>
              <p className="terms-text">All members must be over 16 to avail of facilities and services provided at AB Fitness Dubai Marina.</p>

              <p className="terms-text">AB Fitness Dubai Marina is a private members gym and the management reserve the right to refuse membership to any individual without cause.</p>
              <p className="terms-text">
                Covid-19 restrictions, members will be required to follow government guidance and advice from the Ministry of Health. These restrictions may include reduced services, restricted access and or temporary closure of the gym. In the event of any government ordered temporary closure all memberships will be frozen for the closed period and will restart upon reopening; no time will be lost on membership agreements and no fees will be deducted during closure. Refunds will not be available as a result of Covid-19 restrictions or closures. </p>
              <p className="terms-text">
                Membership may be renewed on payment of the prevailing fee and in accordance with the terms and conditions then in force.
              </p>
              <p className="terms-text">
                By joining the gym, members automatically accept and agree to be bound by these conditions of membership.
              </p>
              <p className="terms-text">
                The gym may withdraw use of all or part of the gym for the purpose of undertaking maintenance work or any other work considered necessary for a maximum period of seven working days.
              </p>
              <p className="terms-text">
                All members must scan their card, finger or input their information at reception for access to the building on each visit to the gym.
              </p>
              <p className="terms-text">
                Entry will only be permitted to those with a valid membership, in date and in credit. If you have an amount due to be paid on your account you won’t be able to enter the building.
              </p>
              <p className="terms-text">
                As a member you agree to comply with the rules of the gym with regards to use of the facilities, opening hours and your conduct. The gym may make reasonable changes to these rules, from time to time, provided the gym gives advance notice of these changes.
              </p>
              <p className="terms-text">
                You may, at your own discretion and expense, obtain personal insurance for loss, injury or damage that you might sustain arising from use of the gym. You exercise at your own discretion and accept any injury or illness brought on by exercise is your own responsibility.
              </p>
              <p className="terms-text">
                Members wishing to report on accidents, incidents, or problems with services at the gym should contact the Duty Manager (simply ask at the reception desk) or can email us at info@abfitness.com.
              </p>
              <p className="terms-text">
                In the event of a breach of membership rules and regulations, AB Fitness Dubai Marina reserves the right of admission and may reserve the right to require any member or guest to leave the premises. Any member found in breach of rules or committing an illegal act, including theft, will be asked to permanently leave the gym and be barred from ever entering the gym again.
              </p>





              <h5>Payments by Credit/Debit Card</h5>
              <p className="terms-text">On selecting the Pay Monthly membership options, members will be required to pay the associated registration fee (if applicable) in advance. Members are required to agree to the normal terms and conditions of payment.</p>
              <p className="terms-text">
                This is a recurring monthly charge. Membership Fees will be debited from the member's account, using their given bank debit/credit card, on or around their join date each month. The gym may offer various MINIMUM FIXED TERM AGREEMENTS on Pay Monthly memberships these can range from 3 months to 12 months depending on the offer at the time.  Members who have chosen a FIXED TERM pay monthly agreement will NOT have a cancel option until after the Fixed Term, after that time they will have the option to cancel at any time.
              </p>
              <p className="terms-text">

                Members who have chosen the Pay Monthly options with NO FIXED TERM can cancel or freeze their agreement at any time, by simply logging in to their Member Area online and pressing the "Cancel" button.</p>
              <p className="terms-text">

                Members are required to keep their account in funds to meet these payments.  In the event of the failure to pay the monthly membership fee, missed payments will be re-submitted for payment by AB Fitness Dubai Marina. AB Fitness Dubai Marina reserves the right to cancel membership if payment is not received. Access to the facility will be denied until said payment is received and if payment is not received, and cancellation occurs, the registration fee becomes applicable again on re-joining.</p>

              <p className="terms-text">These fees must be paid by Credit or Debit card online using the Join Membership page.</p>

              <p className="terms-text">
                Failure to meet direct debit fee – In the event of the failure to pay the monthly direct debit fee, you will first be notified that your membership has been temporarily stopped until the balance is paid.</p>

              <h5>Lockers</h5>
              <p className="terms-text">Lockers are provided for the use of members and guests.</p>

              <p className="terms-text">Lockers may not be used overnight. Members who do so are liable to find that the lockers are opened and locker contents removed. Removal of such items is strictly at the members own risk.</p>

              <p className="terms-text">Padlocks may be purchased at reception.</p>

              <p className="terms-text">In the interest of safety and security please use the lockers provided. The gym will have no liability for goods taken from the changing rooms or lockers.</p>

              <h5>Opening Hours</h5>
              <p className="terms-text">The gym reserves the right to vary the opening hours as considered necessary for the proper operation of the centre.</p>

              <h5>Guests</h5>
              <p className="terms-text">Guests of members are welcome to use the facilities & services of the gym, by purchasing a 1 day gym pass online or at reception. </p>

              <h5>Safety & Hygiene</h5>
              <p className="terms-text">Throughout the course of your membership with the gym, regular health and medical screening should be sought from your General Practitioner.</p>

              <p className="terms-text">Our member's safety during the COVID-19 pandemic is our main priority and we have introduced a number of measures in the gym to help protect members and staff.</p>

              <p className="terms-text">It is necessary that all members and guests of the gym wear appropriate, clean attire and footwear when using the studio and gym.</p>

              <p className="terms-text">If you feel dizzy, faint, unwell or feel any unusual pain then you must stop exercising and inform a member of staff immediately.</p>

              <p className="terms-text">Members cannot train in the gym without a hand towel. In the interest of hygiene, members must wipe down each piece of equipment after use.</p>

              <p className="terms-text">Studio users are advised to inform the instructor of any injuries, pains, or concerns prior to the class starting.</p>

              <h5>Payment Terms & Conditions – AB Fitness Dubai Marina</h5>
              <p className="terms-text">All of the membership rules contained herein apply equally to members, temporary members, guests to our gyms and website users and visitors alike.</p>

              <h5>Your Rights and Responsibilities Using Our Website</h5>
              <p className="terms-text">When you use this website, you agree to abide to civil, commercial, intellectual property, communications and liable laws which apply in the United Arab Emirates regardless of your location. Failure to do so may result in legal action.</p>

              <h5>Terms and Conditions for accessing this website</h5>
              <ul>
                <li>By using this site you agree to be legally bound by these terms and conditions. If you do not agree to be legally bound by all the following terms you should not access and/or use our website or communicate in any way with the server hosting this website, namely, but not limited to www.abfitness.ae</li>

                <li>Henceforth in this legal declaration, you the website user, or associated parties will be known as either "you" or "the client". We, AB Fitness Dubai Marina, will be known as "We", "Us" or "Our Company", our general or individual assets will be addressed by the prefix "Our".</li>

                <li>You agree that we reserve the right to change these, or any, terms and conditions, content and/or declarations without prior notification, and changes made come into immediate effect upon being presented here. When such changes are made, you may revoke your agreement by engaging with our company online using this or any website, or continue to accept such changes.</li>
              </ul>
              <h5>Lawful Use</h5>
              <ul>
                <li>You may not broadcast, copy, download, frame, reproduce, republish, post, transmit or otherwise use our websites content appearing on our website, or any other media, in any way except for your own personal, non-commercial use. Any other use of such content requires permission of our company (contact details available in our privacy policy).</li>

                <li>You agree only to use our website, and associated services for completely lawful purposes, in a way that does not infringe the rights of, restrict or inhibit the use of this site by any third party. Prohibited behaviour includes but is not limited to defamation, damaging the security of our website, harassing or causing distress or inconvenience to any person, damaging content on our website, transmitting obscene or offensive content or anything which may affect the effective operation of our website.</li>

                <li>We reaffirm, hacking, damaging or otherwise compromising the security of this website is highly unlawful under UAE law, and hence will be treated as criminal damage in UAE court.</li>
              </ul>

              <h5>Disclaimers/Limitation of Liability</h5>
              <ul>
                <li>Our websites content, is provided on a "As Is" and on an "voluntary access" basis without any representations or any kind of warranty whether express, implied or statutory of merchantability, fitness for a particular purpose, non-infringement, compatibility, security and accuracy.</li>

                <li>Our company will not be liable for any damages, including consequential damages, or any damages arising from the loss of use, interruption of trade, financial loss or damages, whether in contract, negligence or other tortious action, arising from or in connection with the use of our website.</li>

                <li>We refuse to accept any responsibility for the failure and/or actions of third parties, and content and communication interception which may occasionally occur using our website or associated services.</li>
              </ul>



              <h5>Third Party Content</h5>
              <ul>
                <li>Any third-party content hosted or otherwise presented on our website is copyright of it's respective owners. We do not accept liability for any loss or damage in transmission of such content and in turn do not accept any liability for how content may be misused by our websites users.</li>

                <li> If you notice any third party, unauthorised content displayed on our website or otherwise communicated or transmitted by our company, please contact us (details available in our privacy policy).</li>
              </ul>

              <h5>Third Party Sites/Links</h5>
              <ul>
                <li>Our company not responsible for the availability or content of any third-party websites or material accessed through our website. We do not monitor third party content available through third party websites and/or links as a disclaimer of liability for such content.</li>

                <li>Our company does not endorse, and shall not be held responsible for any content, advertising, products, services or information on or available from third party sites. Third party sites linked through our website are not covered by our terms of service, and hence you must review their policies appropriately.</li>
              </ul>

              <h5>Disclaimer Of Liability for Legal Declarations</h5>
              <ul>
                <li>If any provisions of these terms and conditions are found to be illegal, outdated, less legally preferable in a legal action or otherwise unenforceable by reason of the laws of any state or country in which these terms are intended to be effective, then to the extent and within the jurisdiction in which that term is illegal, invalid or unenforceable, it shall be severed and deleted from these terms and the remaining terms shall survive, remain in full force and effect. We refuse to accept liability for parties who do not make us openly aware of faults and later deny such observations in legal action - Access our website at your own, sole risk and liability without exception.</li>
              </ul>

              <p className="terms-text">These terms shall be governed by and interpreted in accordance with the laws of the United Arab Emirates.</p>


            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TermsConditions;

