import React, { useState, useEffect, useMemo, Fragment } from "react";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import Home from './Pages/Home';
import Pricing from './Pages/Pricing';
import TermsConditions from "./Pages/TermsConditions";
import PrivacyPolicy from "./Pages/PrivacyPolicy";
import Event from "./Pages/Event";
import PersonalTrainers from "./Pages/PersonalTrainers";
import GYM from "./Pages/GYM";
import Classes from "./Pages/Classes";
import Pages from './Layout/Pages';
import NotFound from './NotFound';
function App() {

  return (
    <div className="App">
      <Router>
        <Switch>
          <Route exact path={['/events', '/:language/events']}>
            <Pages Components={Event} footer={true} header={true} />
          </Route>
          <Route exact path={['/personal-trainers', '/:language/personal-trainers']}>
            <Pages Components={PersonalTrainers} footer={true} header={true} />
          </Route>
          <Route exact path={['/gym', '/:language/gym']}>
            <Pages Components={GYM} footer={true} header={true} />
          </Route>
          <Route exact path={['/classes', '/:language/classes']}>
            <Pages Components={Classes} footer={true} header={true} />
          </Route>
          <Route exact path={['/membership', '/:language/membership']}>
            <Pages Components={Pricing} footer={true} header={true} />
          </Route>
          <Route exact path={['/terms-and-conditions', '/:language/terms-and-conditions']}>
            <Pages Components={TermsConditions} footer={true} header={true} />
          </Route>
          <Route exact path={['/privacy-policy', '/:language/privacy-policy']}>
            <Pages Components={PrivacyPolicy} footer={true} header={true} />
          </Route>
          <Route exact path={["/", '/:language']}>
            <Pages Components={Home} footer={true} header={true} />
          </Route>
          <Route>
            <Pages Components={NotFound} footer={true} header={true} />
          </Route>

        </Switch>

      </Router>


    </div>
  );
}

export default App;
