import React, { useState, useEffect, Fragment } from 'react';
import { BrowserRouter as Router, Switch, Route, useHistory, Redirect, useLocation, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Button, Table, Spinner, Accordion, Card, Dropdown, Badge, Modal, DropdownButton } from 'react-bootstrap'
import "antd/dist/antd.min.css";
import './index.scss';
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from "react-redux";
import CarouselProducts from '../../Components/CarouselProducts';
import { useParallax } from "react-scroll-parallax";
const gymMembership = {
  'en': [
    '120,000 sq. ft indoor gym',
    '500+ state-of-the-art equipment',
    'Infinity Pool',
    '125m indoor free-running track',
    'Nutrition and supplement station',
    'Sauna Room',
    'Personal training available',
    'Cycling Studio',
    'Physiotherapy Studio',
    'MMA arena and live fight events',
    'Amazing yacht views',
    'Open 24/7'
  ],
  'ar': [
    'صالة رياضية داخلية بمساحة 120.000 قدم مربع',
    'أكثر من 500 أجهزة رياضية حديثة',
    'حمام سباحة انفينيتي',
    'مضمار جري داخلي بطول 125 م',
    'محطة للأطعمة والمكملات الغذائية',
    'غرفة ساونا',
    'يتوفر التدريب الشخصي',
    'ستوديو الدراجة الهوائية',
    'استوديو العلاج الطبيعي',
    'حلبة لفنون القتال المختلطة والمباريات',
    'إطلالات خلابة على اليخوت',
    'مفتوح 24/7'
  ]
}



function GYM() {
  const { userId, userToken, userEmail, userName, isMember, isPaymentMethod, isCustomer, languageCode, subscription } = useSelector((state) => state.auth);
  const { register, handleSubmit, formState: { errors } } = useForm();
  const history = useHistory();
  const location = useLocation();
  const { t } = useTranslation()
  const dispatch = useDispatch();


  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      // behavior: "smooth"
    });
  }, [])


  const parallax = useParallax({
    // rotate: [0, 0],
    // translateY: ['-500px', '500px'],
    translateY: [0, 50],
    opacity: [1, 0],
    scale: [1.0, 1, 'easeOutCubic'],
    shouldAlwaysCompleteAnimation: true,
  });


  return (
    <div className="page-section">

      <div
        ref={parallax.ref}
        className="section"
        style={{
          background: '#040404',
          position: 'relative',
        }}>
        <div
          style={{
            backgroundImage: 'url(https://cdn.ebtida.com/1710876190277-GYM.webp)',
            backgroundPosition: 'center',
            backgroundSize: 'cover',
            height: '100vh',
            position: 'relative'
          }}>
          <div style={{
            position: 'absolute',
            height: '100%',
            top: 0,
            right: 0,
            left: 0,
            background: '#000000ad'
          }}>
          </div>

          {/* <div style={{ position: 'absolute', right: 0, left: 0, display: 'flex', alignItems: 'center', height: '100%' }}>
            <div className="container">
              <div className='gp-title'>Our GYM</div>
            </div>
          </div> */}
        </div>



        <div className='section' style={{ position: 'absolute', right: 0, left: 0, bottom: '0px', padding: '40px 0px 40px 0px', height: '100%', display: 'flex', alignItems: 'center' }}>
          <div className="container">

            <div className="row">
              <div className={`col-md-12`}>
                <br /><br />
                <div className='gp-title'>Our GYM</div>
                <br /><br />
              </div>
            </div>

            <div className="row gym-m-overflow">
              <div className={`col-md-3`}>
                <div style={{ textAlign: 'center', padding: '15px' }}>
                  <div><img style={{ width: '75px' }} src='https://cdn.ebtida.com/1711625364478-nutrition-tips.png.webp' /></div>
                  <div style={{ color: '#eeed32', fontSize: '1.5rem', padding: '10px 0px 0px 0px' }}>Nutrition Tips</div>
                </div>
              </div>
              <div className={`col-md-3`}>
                <div style={{ textAlign: 'center', padding: '15px' }}>
                  <div><img style={{ width: '75px' }} src='https://cdn.ebtida.com/1711625370053-weight-loss.png.webp' /></div>
                  <div style={{ color: '#eeed32', fontSize: '1.5rem', padding: '10px 0px 0px 0px' }}>Weight Loss</div>
                </div>
              </div>
              <div className={`col-md-3`}>
                <div style={{ textAlign: 'center', padding: '15px' }}>
                  <div><img style={{ width: '75px' }} src='https://cdn.ebtida.com/1711625374916-muscle-building.png.webp' /></div>
                  <div style={{ color: '#eeed32', fontSize: '1.5rem', padding: '10px 0px 0px 0px' }}>Muscle Building</div>
                </div>
              </div>
              <div className={`col-md-3`}>
                <div style={{ textAlign: 'center', padding: '15px' }}>
                  <div><img style={{ width: '75px' }} src='https://cdn.ebtida.com/1711625380202-yoga-mindfulness.png.webp' /></div>
                  <div style={{ color: '#eeed32', fontSize: '1.5rem', padding: '10px 0px 0px 0px' }}>Yoga & Mindfulness</div>
                </div>
              </div>
            </div>
          </div>
        </div>


      </div>
      <div style={{ background: '#000', position: 'relative' }}>
        <div className="container">
          <div className="row" style={{ justifyContent: 'center' }}>
            <div className="col-md-8">
              <div className="mobile-padding" style={{ padding: '50px', textAlign: 'center' }}>
                <p style={{ color: '#fff' }}>
                  At <span style={{ color: '#f7ee23' }}>AB MARINA FITNESS</span> Gym, we’re more than just a fitness facility;
                  we’re a community that thrives on pushing boundaries and achieving greatness together.
                  Our state-of-the-art gym is designed to cater to all fitness levels and goals, providing you with the tools and support needed to conquer your fitness journey. Whether you’re a seasoned athlete or a fitness novice, <span style={{ color: '#f7ee23' }}>we’ve got everything you need to succeed.</span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>


      <CarouselProducts
        title={t('gallery')}
        languageCode={languageCode}
        background={'#fff0'}
        backgrounds={'#000'}
        padding={'0px'}
        getLanguage={languageCode}
      />


      <br />
      <br />
    </div>

  );
}

export default GYM;
